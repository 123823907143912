import React, { useCallback, useEffect, useState } from "react";
import { useAuthContext } from "../../context/AuthContext";
import { apiService } from "../../services/apiService";
import moment from "moment";
import * as XLSX from "xlsx";

const Report = () => {
  const { authUser, setIsLoading } = useAuthContext();
  const [data, setData] = useState([]);
  const [dataTitle, setDataTitle] = useState([]);
  const getReport = useCallback(async () => {
    setIsLoading(true);
    const resp = await apiService.post("", {
      operation: "admin_report",
      user_name: authUser.user.user_name,
      request_date: moment(new Date()).format("YYYY-MM-DD"),
      token: authUser.token,
    });
    if (resp.data.status === 200) {
      setData(resp.data.users);
      let list = [];
      resp.data.users[0].activities.forEach((item, i) => {
        list.push(item);
      });
      setDataTitle(list);
    }
    setIsLoading(false);
  }, [authUser.user.user_name, authUser.token, setIsLoading]);

  const handleExport = () => {
    var elt = document.getElementById("table-to-xls");
    var wb = XLSX.utils.table_to_book(elt, { sheet: "sheet1" });
    XLSX.writeFile(
      wb,
      `triwizard-${new Date().getDate()}-${new Date().getMonth()}-${new Date().getFullYear()}.xlsx`
    );
  };

  useEffect(() => {
    getReport();
  }, [getReport]);

  return (
    <div className="bg-white rounded shadow p-4 overflow-auto w-full text-sm">
      {data.length > 0 && (
        <>
          <button
            onClick={handleExport}
            className="bg-theme-yellow text-white px-4 py-2 mb-4 rounded shadow "
          >
            Download as XLSX
          </button>
          <div className="overflow-auto w-full">
            <table className="relative w-full" id="table-to-xls">
              <thead>
                <tr>
                  <th className="align-top sticky top-0">User</th>
                  <th className="align-top sticky top-0">Role</th>
                  <th className="align-top sticky top-0">Region</th>
                  {dataTitle.map((item) => {
                    return (
                      <th
                        key={item.activity_id}
                        className="align-top sticky top-0"
                      >
                        {item.title}
                      </th>
                    );
                  })}
                  <th className="align-top sticky top-0">Total</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item.name_of_employee}</td>
                      <td>{item.role}</td>
                      <td>{item.region}</td>
                      {item.activities.map((i, j) => {
                        return <td key={j}>{i.score}</td>;
                      })}
                      <td>{item.total_score}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {/* <div className="overflow-auto w-full">
            <table className="relative w-full" id="table-to-xls">
              <thead>
                <tr>
                  <th className="align-top sticky top-0">User</th>
                  {dataTitle.map((item) => {
                    return (
                      <th
                        key={item.activity_id}
                        className="align-top sticky top-0"
                      >
                        {item.title}
                      </th>
                    );
                  })}
                  <th className="align-top sticky top-0">Total</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item.name_of_employee}</td>
                      {item.activities.map((i, j) => {
                        return <td key={j}>{i.score}</td>;
                      })}
                      <td>{item.total_score}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div> */}
        </>
      )}
    </div>
  );
};

export default Report;
