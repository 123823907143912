import { Outlet } from "react-router-dom";
import ClientSidebar from "../components/ClientSidebar";
import { useAuthContext } from "../context/AuthContext";
import Header from "../components/Header";
import AdminSidebar from "../components/AdminSidebar";
import BadgeArchivement from "../components/BadgeArchivement";

const Dashboardlayout = () => {
  const { iscollapsed, setIscollapsed, authUser } = useAuthContext();
  return (
    <>
      <main className="min-h-screen w-full">
        <>
          {authUser.role === "admin" ? <AdminSidebar /> : <ClientSidebar />}

          {!iscollapsed && window.innerWidth < 1025 && (
            <div
              className="bg-gray-900/75 fixed top-0 left-0 w-full h-full z-[9]"
              onClick={(e) => setIscollapsed(!iscollapsed)}
            ></div>
          )}
          <div
            className={` transition-all ease-in-out duration-300 
        
        ${window.innerWidth < 1025 || iscollapsed ? "ml-0" : "ml-72"}
      `}
          >
            <Header />
            <div className="container mx-auto p-4">
              <Outlet />
            </div>
          </div>
        </>
        {authUser.role !== "admin" && <BadgeArchivement />}
      </main>
    </>
  );
};

export default Dashboardlayout;
