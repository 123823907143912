import { useEffect } from "react";
import { useAuthContext } from "../../context/AuthContext";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Logo from "./../../assets/Asset 1.png";
import { useLocation, useNavigate, Link } from "react-router-dom";
import LoginBg from "./../../assets/loginBg.jpg";
const ForgetPassword = () => {
  const { authUser } = useAuthContext();
  let navigate = useNavigate();
  let location = useLocation();
  let { from } = location.state || { from: { pathname: "/" } };
  const validationSchema = Yup.object().shape({
    loginId: Yup.string().required("GE Email Id is required"),
  });

  useEffect(() => {
    if (authUser) {
      navigate(from);
    }
  }, [authUser, from, navigate]);
  return (
    <div
      className="min-h-screen flex items-center justify-center w-full flex-col px-6 bg-center bg-cover"
      style={{ backgroundImage: `url(${LoginBg})` }}
    >
      <div className="max-w-[420px] mx-auto p-6 flex flex-col justify-center gap-8 bg-white rounded-md shadow-lg">
        <img src={Logo} alt="logo" width={"256px"} className="mx-auto" />
        <div className="text-slate-800 text-center text-sm">
          Enter the email associated with your account and admin will send email
          with instructions to reset your password.
        </div>

        <Formik
          initialValues={{
            loginId: "",
          }}
          validationSchema={validationSchema}
          onSubmit={(val) => {
            console.log(val);
            window.open(
              `mailto:Sanil.Chawla@ge.com, sachin.desai@solmc.in?subject=Please reset my password&body=Hello Admin, I have forgot my password for TRIWIZARD App, my GE email id is ${val.loginId}, Please reset my password`
            );
          }}
        >
          {({
            handleBlur,
            handleSubmit,
            errors,
            isValid,
            dirty,
            handleChange,
          }) => (
            <>
              <Form className="space-y-3" onSubmit={handleSubmit}>
                <div className="relative group">
                  <label htmlFor="loginId" className="form-label">
                    GE Email ID
                  </label>
                  <Field
                    id="loginId"
                    name="loginId"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="form-control"
                  />
                  <ErrorMessage
                    name="loginId"
                    component="div"
                    className="hasError"
                  />
                </div>
                <button
                  type="submit"
                  className="btn w-full"
                  disabled={!(isValid && dirty)}
                >
                  Request for reset password
                </button>
                <div className="text-right">
                  <Link to="/" className="text-sm">
                    Go back to login
                  </Link>
                </div>
              </Form>
            </>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ForgetPassword;
