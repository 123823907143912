import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import SubNav from "../../../components/SubNav";
import { useAdminContext } from "../../../context/AdminContext";
import { apiService } from "../../../services/apiService";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { useAuthContext } from "../../../context/AuthContext";
import { toast } from "react-toastify";

const UserProfile = () => {
  const { user_id } = useParams();
  const [userData, setUserData] = useState(null);
  const { authUser, setIsLoading } = useAuthContext();
  const { allUsers } = useAdminContext();
  const validationSchema = Yup.object().shape({
    new_password: Yup.string().required("New Password is required"),
  });

  useEffect(() => {
    if (allUsers.length > 0) {
      setUserData(allUsers.find((item) => item.user_id === user_id));
    }
  }, [allUsers, user_id]);

  const actionChangePassword = async (new_password) => {
    setIsLoading(true);
    try {
      const resp = await apiService.post("", {
        operation: "change_password",
        old_password: "",
        user_name: userData.user_name,
        request_date: moment(new Date()).format("YYYY-MM-DD"),
        token: authUser.token,
        new_password,
      });
      if (resp.data.status === 200) {
        toast.success("Password reset successfully");
      }
      setIsLoading(false);
    } catch (error) {
      toast.error(error.message);
      console.log(error);
      setIsLoading(false);
    }
  };

  return (
    <div>
      <SubNav title={userData ? userData.name_of_employee : "loading"} />
      {userData && (
        <div className="grid md:grid-cols-3 lg:grid-cols-3 gap-1 mb-6">
          <div className="px-4 py-3 bg-white rounded shadow">
            <label className="text-sm text-slate-500">Name</label>
            <p>{userData.name_of_employee}</p>
          </div>
          <div className="px-4 py-3 bg-white rounded shadow">
            <label className="text-sm text-slate-500">Email</label>
            <p>{userData.user_name}</p>
          </div>
          <div className="px-4 py-3 bg-white rounded shadow">
            <label className="text-sm text-slate-500">Region</label>
            <p>{userData.region}</p>
          </div>
          <div className="px-4 py-3 bg-white rounded shadow">
            <label className="text-sm text-slate-500">Designation</label>
            <p>{userData.designation}</p>
          </div>
          <div className="px-4 py-3 bg-white rounded shadow">
            <label className="text-sm text-slate-500">Mobile no</label>
            <p>{userData.mobile_no}</p>
          </div>
          <div className="px-4 py-3 bg-white rounded shadow">
            <label className="text-sm text-slate-500">Points</label>
            <p>{userData.current_score}</p>
          </div>
        </div>
      )}
      <SubNav title="Reset password" />
      <div className="bg-white p-4 lg:p-8 shadow rounded">
        <Formik
          validationSchema={validationSchema}
          initialValues={{
            new_password: "",
          }}
          onSubmit={(val, { resetForm }) => {
            actionChangePassword(val.new_password);
            resetForm();
          }}
        >
          {({
            values,
            handleBlur,
            handleSubmit,
            errors,
            isValid,
            dirty,
            handleChange,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit}>
              <div className="space-y-3 max-w-sm">
                <div className="relative group">
                  <label htmlFor="new_password" className="form-label">
                    New password
                  </label>
                  <Field
                    id="new_password"
                    name="new_password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="form-control"
                  />
                  <ErrorMessage
                    name="new_password"
                    component="div"
                    className="hasError"
                  />
                </div>
                <button
                  type="submit"
                  className="btn w-full"
                  disabled={!(isValid && dirty)}
                >
                  Submit
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default UserProfile;
