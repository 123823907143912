import React, { useCallback, useEffect, useState } from "react";
import SubNav from "../../components/SubNav";
import { useAuthContext } from "../../context/AuthContext";
import moment from "moment";
import { apiService } from "../../services/apiService";
import mmt from "./../../assets/makemytrip.jpg";

const ShowReward = () => {
  const [rewards, setRewards] = useState([]);
  const { authUser, setIsLoading } = useAuthContext();
  const fetchRewards = useCallback(async () => {
    try {
      const resp = await apiService.post("", {
        operation: "get_all_rewards",
        token: authUser.token,
        user_name: authUser.user.user_name,
        request_date: moment(new Date()).format("YYYY-MM-DD"),
      });
      if (resp.data.status === 200) {
        let sorted = resp.data.rewards
          .filter((item) => item.month <= new Date().getMonth() + 1)
          .sort((a, b) => b.month - a.month)
          .filter((rw) => rw.role === authUser.role);
        setRewards(sorted);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  }, [setIsLoading, authUser.token, authUser.user.user_name, authUser.role]);
  useEffect(() => {
    fetchRewards();
  }, [fetchRewards]);

  // console.log(rewards);

  return (
    <div>
      <SubNav title="Rewards" />
      <div className="bg-white rounded shadow p-4 lg:p-8 grid md:grid-cols-3 gap-4">
        <div className="col-span-1">
          <div className="text-center py-3 px-4  bg-gradient-to-r from-theme-yellow-500 to-purple-500 text-white relative shadow-lg">
            Triwizard Winner
          </div>
          <div>
            <img src={mmt} alt="reward" />
          </div>
          <div className="text-center py-3 px-4  bg-gradient-to-r from-theme-yellow-500 to-purple-500 text-white relative shadow-lg">
            Makemytrip ₹1,00,000 Voucher
          </div>
        </div>
        {rewards.length > 0 &&
          rewards.map((item, index) => {
            return (
              <div key={index} className="col-span-1">
                <div className="text-center py-3 px-4  bg-gradient-to-r from-theme-yellow-500 to-purple-500 text-white relative shadow-lg">
                  {moment(new Date().setMonth(item.month - 1)).format("MMMM")}
                </div>
                <div>
                  <img src={item.url} alt="reward" />
                </div>
                <div className="text-center py-3 px-4  bg-gradient-to-r from-theme-yellow-500 to-purple-500 text-white relative shadow-lg">
                  {item.title}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default ShowReward;
