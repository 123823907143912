import React from "react";
import { NavLink, Link } from "react-router-dom";
import { useAuthContext } from "../context/AuthContext";
import Logo from "./../assets/Asset 1.png";
import {
  IoHomeOutline,
  IoTrophyOutline,
  IoGiftOutline,
  IoShieldCheckmarkOutline,
  IoPersonAddOutline,
  IoPersonCircleSharp,
  IoPersonCircleOutline,
} from "react-icons/io5";
import BadgeGold from "./../assets/badge-gold.svg";
import BadgeSilver from "./../assets/badge-silver.svg";
import BadgeBronze from "./../assets/badge-bronze.svg";
import { useClientContext } from "../context/ClientContext";

const navlinks = [
  {
    name: "Home",
    path: "/",
    icon: <IoHomeOutline className="text-xl" />,
  },
  {
    name: "Profile",
    path: "/profile",
    icon: <IoPersonCircleOutline className="text-xl" />,
  },
  {
    name: "Leaderboard",
    path: "/leaderboard",
    icon: <IoTrophyOutline className="text-xl" />,
  },
  {
    name: "Rewards",
    path: "/show-rewards",
    icon: <IoGiftOutline className="text-xl" />,
  },
  {
    name: "Eligibility",
    path: "/eligibility",
    icon: <IoShieldCheckmarkOutline className="text-xl" />,
  },
];
const ClientSidebar = () => {
  const { authUser, iscollapsed, setIscollapsed } = useAuthContext();
  const { percentage } = useClientContext();

  const selfCollapsed = () => {
    if (window.innerWidth < 1025) {
      setIscollapsed(true);
    }
  };
  return (
    <aside
      className={`fixed left-0 top-0 w-72 bg-gray-900 h-full overflow-y-auto transition-all ease-in-out duration-300 z-10
     ${iscollapsed ? "-ml-72" : "ml-0"}
      `}
    >
      <Link
        to="/"
        className="p-2 flex items-center justify-center h-16 bg-white"
      >
        <img src={Logo} alt="triwizard logo" className="h-full" />
      </Link>
      <div className="flex bg-[#642D91] items-center relative gap-2">
        <div className="w-16 h-16 relative z-[1] overflow-hidden flex-none">
          {authUser?.user?.photo_url ? (
            <img src={authUser.user.photo_url} alt="user" />
          ) : (
            <IoPersonCircleSharp className="text-6xl mx-auto my-1 text-theme-yellow-200" />
          )}
        </div>
        <div className="overflow-hidden pr-2 font-semibold text-white">
          <h4 className="truncate">{authUser.user.name_of_employee}</h4>
          <p className="truncate text-sm">{authUser.user.user_name}</p>
        </div>
      </div>
      {/* <div className="flex px-10 items-center justify-center gap-4 text-2xl bg-slate-800 pb-2">
        <img
          src={BadgeBronze}
          alt="badge"
          className={`w-8 ${percentage >= 25 ? "opacity-100" : "opacity-20"}`}
        />
        <img
          src={BadgeSilver}
          alt="badge"
          className={`w-8 ${percentage >= 50 ? "opacity-100" : "opacity-20"}`}
        />
        <img
          src={BadgeGold}
          alt="badge"
          className={`w-8 ${percentage >= 75 ? "opacity-100" : "opacity-20"}`}
        />
      </div> */}
      <div className="flex flex-col items-start justify-center font-semibold p-4 gap-1 sidebarNav">
        {navlinks.map((navitem, index) => {
          return (
            <NavLink key={index} to={navitem.path} onClick={selfCollapsed}>
              {navitem.icon}
              {navitem.name}
            </NavLink>
          );
        })}
        {authUser.role === "admin" && (
          <NavLink to="add-user">
            <IoPersonAddOutline className="text-xl" /> Add User
          </NavLink>
        )}
      </div>
    </aside>
  );
};

export default ClientSidebar;
