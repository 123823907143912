const SubNav = ({
  title,
  subtitle,
  desc,
  activity_score,
  target,
  totalScore,
}) => {
  return (
    <div className="mb-3 flex items-center gap-4 flex-wrap">
      <div className="">
        <div className="text-lg text-slate-800 font-semibold">{title}</div>
        {subtitle && (
          <div className="text-base text-slate-800 font-semibold ">
            {subtitle}
          </div>
        )}
        <div className="text-sm text-slate-800 ">{desc}</div>
      </div>

      {target && (
        <div className="bg-green-500 text-white flex items-center justify-center px-2 py-1 rounded">
          Current Points: {activity_score} / {target}
        </div>
      )}
      {totalScore && (
        <div className="bg-green-500 text-white flex items-center justify-center px-2 py-1 rounded">
          Total Score: {totalScore}
        </div>
      )}
    </div>
  );
};

export default SubNav;
