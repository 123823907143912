import { Link } from "react-router-dom";

const Unauthorised = () => {
  return (
    <div className="bg-gradient-to-br from-theme-yellow-500 to-theme-yellow-100/5 w-full min-h-screen flex items-center justify-center">
      <div className="space-y-4 flex flex-col items-start">
        <h4 className="text-white font-bold text-4xl">
          404 Unauthorised
          <br />
          The page not found
        </h4>
        <p className="font-semibold text-lg">
          The link you followed os broken
          <br /> or the page isn't exist
        </p>
        <Link to="/" className="btn ">
          Go to HomePage
        </Link>
      </div>
    </div>
  );
};

export default Unauthorised;
