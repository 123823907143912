import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import SubNav from "../../../components/SubNav";
import { useAdminContext } from "../../../context/AdminContext";

const AddUser = () => {
  const { addUser } = useAdminContext();
  const validationSchema = Yup.object().shape({
    name_of_employee: Yup.string().required("Full name is required"),
    user_name: Yup.string().email().required("Email Id is required"),
    password: Yup.string().required("Password is required"),
    mobile_no: Yup.number().required("Mobile number is required"),
    designation: Yup.string().required("Designation is required"),
    region: Yup.string().required("Region is required"),
  });
  return (
    <>
      <SubNav title="Add new user" />
      <div className="bg-white p-4 lg:p-8 w-full shadow rounded ">
        <Formik
          initialValues={{
            name_of_employee: "",
            user_name: "",
            password: "",
            mobile_no: "",
            designation: "",
            region: "",
          }}
          validationSchema={validationSchema}
          onSubmit={(val, { resetForm }) => {
            addUser(
              val.name_of_employee,
              val.user_name,
              val.password,
              val.mobile_no,
              val.designation,
              val.region
            );
            resetForm();
          }}
        >
          {({
            handleBlur,
            handleSubmit,
            errors,
            isValid,
            dirty,
            handleChange,
          }) => (
            <>
              <Form className="space-y-4" onSubmit={handleSubmit}>
                <div className="grid md:grid-cols-2 xl:grid-cols-3 gap-4">
                  <div className="relative group">
                    <label htmlFor="name_of_employee" className="form-label">
                      Full name
                    </label>
                    <Field
                      id="name_of_employee"
                      name="name_of_employee"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="form-control"
                    />
                    <ErrorMessage
                      name="name_of_employee"
                      component="div"
                      className="hasError"
                    />
                  </div>
                  <div className="relative group">
                    <label htmlFor="user_name" className="form-label">
                      Email ID
                    </label>
                    <Field
                      type="email"
                      id="user_name"
                      name="user_name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="form-control"
                    />
                    <ErrorMessage
                      name="user_name"
                      component="div"
                      className="hasError"
                    />
                  </div>
                  <div className="relative group">
                    <label htmlFor="password" className="form-label">
                      Password
                    </label>
                    <Field
                      type="password"
                      id="password"
                      name="password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="form-control"
                    />
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="hasError"
                    />
                  </div>
                  <div className="relative group">
                    <label htmlFor="mobile_no" className="form-label">
                      Mobile No.
                    </label>
                    <Field
                      type="number"
                      id="mobile_no"
                      name="mobile_no"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="form-control"
                    />
                    <ErrorMessage
                      name="mobile_no"
                      component="div"
                      className="hasError"
                    />
                  </div>
                  <div className="relative group">
                    <label htmlFor="designation" className="form-label">
                      Designation
                    </label>
                    <Field
                      as="select"
                      id="designation"
                      name="designation"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="form-control"
                    >
                      <option value="" disabled>
                        Select{" "}
                      </option>
                      <option value="Territory Manager">
                        Territory Manager
                      </option>
                      <option value="AM">AM</option>
                      <option value="KAM">KAM</option>
                      <option value="GAM">GAM</option>
                    </Field>
                    <ErrorMessage
                      name="designation"
                      component="div"
                      className="hasError"
                    />
                  </div>
                  <div className="relative group">
                    <label htmlFor="region" className="form-label">
                      Region
                    </label>
                    <Field
                      as="select"
                      id="region"
                      name="region"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="form-control"
                    >
                      <option value="" disabled>
                        Select{" "}
                      </option>
                      <option value="East">East</option>
                      <option value="West">West</option>
                      <option value="North 1">North 1</option>
                      <option value="North 2">North 2</option>
                      <option value="South 1">South 1</option>
                      <option value="South 2">South 2</option>
                    </Field>
                    <ErrorMessage
                      name="region"
                      component="div"
                      className="hasError"
                    />
                  </div>
                </div>
                <button
                  type="submit"
                  className="btn"
                  disabled={!(isValid && dirty)}
                >
                  Submit
                </button>
              </Form>
            </>
          )}
        </Formik>
      </div>
    </>
  );
};

export default AddUser;
