import { Navigate, useLocation, Outlet } from "react-router-dom";
import { useAuthContext } from "./context/AuthContext";
const ProtectedRoute = ({ allowedRole }) => {
  let location = useLocation();
  const { authUser } = useAuthContext();

  return authUser && allowedRole.find((r) => authUser.role) ? (
    <Outlet />
  ) : authUser ? (
    <Navigate to="/unauthorized" state={{ from: location }} />
  ) : (
    <Navigate to="/login" state={{ from: location }} />
  );
};

export default ProtectedRoute;
