import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  scales: {
    y: {
      min: 0,
      max: 4000,
      ticks: {
        stepSize: 1000,
      },
    },
  },
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Monthly report",
    },
  },
};
const labels = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const BarChartPanel = ({ monthlyScore }) => {
  const [currentMonthScore, setCurrentMonthScore] = useState("");
  const [allmonthsscore, setAllmonthsscore] = useState([]);
  const [xAxisData, setXAxisData] = useState([]);
  const [yAxisData, setYAxisData] = useState([]);

  useEffect(() => {
    if (monthlyScore[new Date().getFullYear()]) {
      setCurrentMonthScore(monthlyScore["2022"][new Date().getMonth()].score);
      setAllmonthsscore(monthlyScore["2022"].slice(3, 12));
    }
  }, [monthlyScore]);

  useEffect(() => {
    let monthArr = [];
    let scoreArr = [];
    allmonthsscore.forEach((item) => {
      monthArr.push(labels[item.month - 1]);
      scoreArr.push(item.score);
    });
    setXAxisData(monthArr);
    setYAxisData(scoreArr);
  }, [allmonthsscore]);

  const data = {
    labels: xAxisData,
    datasets: [
      {
        label: "Score",
        data: yAxisData,
        backgroundColor: "#642D91",
      },
    ],
  };

  return (
    <>
      <div className="flex gap-4 justify-between items-center">
        <h4 className="font-bold text-lg">
          Current month points : {currentMonthScore}
        </h4>
      </div>
      <div className="w-full">
        <Bar options={options} data={data} />
      </div>
    </>
  );
};

export default BarChartPanel;
