import React from "react";
import moment from "moment";
import { useAuthContext } from "../../../context/AuthContext";
import { useClientContext } from "../../../context/ClientContext";

const resp = {
  status: "200",
  message: "Success",
  current_score: "211",
  target_score: "11100",
  years: {
    2024: [
      {
        month: "1",
        score: 0,
      },
      {
        month: "2",
        score: 0,
      },
      {
        month: "3",
        score: 0,
      },
      {
        month: "4",
        score: 0,
      },
      {
        month: "5",
        score: 0,
      },
      {
        month: "6",
        score: 0,
      },
      {
        month: "7",
        score: 0,
      },
      {
        month: "8",
        score: 0,
      },
      {
        month: "9",
        score: 0,
      },
      {
        month: "10",
        score: 161,
      },
      {
        month: "11",
        score: 50,
      },
      {
        month: "12",
        score: 0,
      },
    ],
  },
  activities: [
    {
      activity_id: "activity_1",
      title: "Win Back",
      subtitle: null,
      once_a_month: false,
      description: "",
      activity_limit: 100,
      score_per_activity: 50,
      activities_considered: 3,
      score: 150,
      details: [
        {
          id: "15",
          account_name: "rt",
          ucm_id: "879",
          omniscan: "200",
          clariscan: "200",
          month: "10",
          year: "2024",
          timestamp: "2024-10-29 12:01:13",
          can_delete: true,
        },
        {
          id: "16",
          account_name: "ac",
          ucm_id: "567",
          omniscan: "100",
          clariscan: "100",
          month: "10",
          year: "2024",
          timestamp: "2024-10-29 12:06:39",
          can_delete: true,
        },
        {
          id: "17",
          account_name: "test",
          ucm_id: "23556",
          omniscan: "10",
          clariscan: "10",
          month: "11",
          year: "2024",
          timestamp: "2024-11-04 21:27:10",
          can_delete: true,
        },
      ],
    },
    {
      activity_id: "activity_2",
      title: "New IB",
      subtitle: null,
      once_a_month: false,
      description: "",
      activity_limit: 100,
      score_per_activity: 50,
      activities_considered: 1,
      score: 50,
      details: [
        {
          id: "12",
          account_name: "rt",
          ucm_id: "879",
          omniscan: "100",
          clariscan: "0",
          month: "10",
          year: "2024",
          timestamp: "2024-10-29 12:01:38",
          can_delete: true,
        },
        {
          id: "18",
          account_name: "rtt",
          ucm_id: "8793",
          omniscan: "50",
          clariscan: "60",
          month: "10",
          year: "2024",
          timestamp: "2024-10-29 12:01:38",
          can_delete: true,
        },
      ],
    },
    {
      activity_id: "activity_3",
      title: "SKU volume",
      subtitle: null,
      once_a_month: false,
      description: "",
      activity_limit: 100,
      score_per_activity: 1,
      activities_considered: 1,
      score: 1,
      details: [],
    },
    {
      activity_id: "activity_4",
      title: "MR Safety Techmini",
      subtitle: null,
      once_a_month: false,
      description: "",
      activity_limit: 100,
      score_per_activity: 10,
      activities_considered: 1,
      score: 10,
      details: [
        {
          id: "6",
          account_name: "rt",
          ucm_id: "879",
          month: "10",
          year: "2024",
          timestamp: "2024-10-29 12:18:45",
          image_urls: [],
          can_delete: true,
        },
      ],
    },
  ],
};

const Activity3 = ({ item, activity_id, actionDeleteEntry }) => {
  // console.log(item, "item");

  const { authUser } = useAuthContext();
  const { response, setResponse } = useClientContext();
  const totals = {};

  response.activities.forEach((activity) => {
    if (["activity_1", "activity_2"].includes(activity.activity_id)) {
      activity.details.forEach((detail) => {
        const month = detail.month;
        const year = detail.year;
        const omniscan = parseInt(detail.omniscan, 10);
        const clariscan = parseInt(detail.clariscan, 10);

        const monthYearKey = `${month}-${year}`;

        if (!totals[monthYearKey]) {
          totals[monthYearKey] = { omniscan: 0, clariscan: 0, month, year };
        }

        totals[monthYearKey].omniscan += omniscan;
        totals[monthYearKey].clariscan += clariscan;
      });
    }
  });

  const totalsArray = Object.entries(totals).map(([month, values]) => ({
    month,
    ...values,
  }));

  const formatMonthYear = (month, year) => {
    const date = new Date(year, month - 1);
    return new Intl.DateTimeFormat("en-US", {
      month: "long",
      year: "numeric",
    }).format(date);
  };

  return (
    <>
      {/* <table>
        <thead>
          <tr>
            <th>Sr.</th>
            <th>Omniscan Qty.</th>
            <th>Clariscan Qty.</th>
            <th>Activity</th>
            {authUser.role !== "admin" && <th>Action</th>}
          </tr>
        </thead>
        <tbody>
          {item.details.reverse().map((item, index) => {
            return (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{item.omniscan}</td>
                <td>{item.clariscan}</td>
                <td>
                  {moment(new Date().setMonth(item.month - 1)).format("MMMM")}
                  &nbsp;
                  {moment(new Date().setFullYear(item.year)).format("YYYY")}
                </td>
                {authUser.role !== "admin" && (
                  <td>
                    <button
                      className="btn !bg-red-500 !py-1 !text-xs !rounded-sm"
                      disabled={!item.can_delete}
                      onClick={(e) =>
                        actionDeleteEntry({
                          id: item.id,
                          activity_id: activity_id,
                        })
                      }
                    >
                      Delete
                    </button>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table> */}

      <table>
        <thead>
          <tr>
            <th>Omniscan Total</th>
            <th>Clariscan Total</th>
            <th>Month</th>
          </tr>
        </thead>
        <tbody>
          {totalsArray.map(({ month, year, omniscan, clariscan }) => (
            <tr key={`${month}-${year}`}>
              <td>{omniscan}</td>
              <td>{clariscan}</td>
              <td>{formatMonthYear(month, year)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default Activity3;
