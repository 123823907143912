import React from "react";
import { Link } from "react-router-dom";
import SubNav from "../../../components/SubNav";
import moment from "moment";
import { IoTrashBinOutline } from "react-icons/io5";
import { useAdminContext } from "../../../context/AdminContext";
import Fancybox from "../../../helpers/fancybox";
const Rewards = () => {
  const { rewards, actionRemoveReward } = useAdminContext();
  return (
    <>
      <SubNav title="Rewards" />
      <div className="bg-white p-4 lg:p-8">
        <Link to="/add-reward" className="btn">
          Add reward
        </Link>
      </div>
      <div className="bg-white rounded shadow p-4 lg:p-8 overflow-auto w-full">
        {rewards && rewards.length > 0 && (
          <Fancybox>
            <table>
              <thead>
                <tr>
                  <th>Sr.</th>
                  <th>Title</th>
                  <th>Month</th>
                  <th>Role</th>
                  <th>Img</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {rewards.reverse().map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.title}</td>
                      <td>
                        {moment(new Date().setMonth(item.month - 1)).format(
                          "MMMM"
                        )}
                        &nbsp;
                        {moment(new Date().setFullYear(item.year)).format(
                          "YYYY"
                        )}
                      </td>
                      <td>{item.role}</td>
                      <td>
                        <a data-fancybox href={item.url}>
                          <img src={item.url} width="100px" alt="banner" />
                        </a>
                      </td>
                      <td>
                        <div className="flex flex-col space-y-2 items-start">
                          {/* <button
                            type="button"
                            className="btn !py-1 flex gap-2 items-center !bg-blue-500"
                          >
                            <IoCreateOutline /> Edit
                          </button> */}
                          <button
                            type="button"
                            onClick={(e) => actionRemoveReward(item)}
                            className="btn !py-1 flex gap-2 items-center !bg-red-500"
                          >
                            <IoTrashBinOutline /> Delete
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Fancybox>
        )}
      </div>
    </>
  );
};

export default Rewards;
