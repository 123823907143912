import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import SubNav from "../../../components/SubNav";
import { useClientContext } from "../../../context/ClientContext";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import moment from "moment";
import NoDataFound from "../../../components/NoDataFound";
import Activity1 from "./Activity1";

const CheckmateNewIbConversions = () => {
  const { allActivities, submitActivity, actionDeleteEntry } =
    useClientContext();
  const { pathname } = useLocation();
  const [currentActivity, setCurrentActivity] = useState(null);

  useEffect(() => {
    if (allActivities.length) {
      let myActivity = allActivities.find((item) => {
        return item.activity_id === pathname.substring(1);
      });
      setCurrentActivity(myActivity);
    }
  }, [allActivities, pathname]);

  const validationSchema = Yup.object().shape({
    account_name: Yup.string().required("Account name is required"),
    ucm_id: Yup.string().required("UCM id is required"),
    omniscan: Yup.number().required("Omniscan is required"),
    clariscan: Yup.number().required("Clariscan is required"),
    selectedMonth: Yup.string().required("Month is required"),
    accept: Yup.bool().oneOf([true], "You must accept"),
  });

  if (currentActivity === null) {
    return "loading";
  }
  // console.log(currentActivity);

  return (
    <div>
      <SubNav
        title={currentActivity.title}
        subtitle={currentActivity.subtitle}
        desc={currentActivity.description}
        activity_score={currentActivity.score}
        target={
          currentActivity.activity_limit * currentActivity.score_per_activity
        }
      />
      <div className="grid gap-4 lg:grid-cols-3 ">
        <div className="bg-white p-4 lg:p-8 w-full shadow rounded">
          <Formik
            initialValues={{
              account_name: "",
              ucm_id: "",
              omniscan: "",
              clariscan: "",
              selectedMonth: "",
              accept: false,
            }}
            validationSchema={validationSchema}
            onSubmit={(val, { resetForm }) => {
              submitActivity({
                ...val,
                activity_id: currentActivity.activity_id,
              });
              resetForm();
              // console.log(val);
              // navigate("/");
            }}
          >
            {({
              handleBlur,
              handleSubmit,
              errors,
              isValid,
              dirty,
              handleChange,
            }) => (
              <>
                <Form className="space-y-3" onSubmit={handleSubmit}>
                  <div className="relative group">
                    <label htmlFor="account_name" className="form-label">
                      Account Name
                    </label>
                    <Field
                      id="account_name"
                      name="account_name"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="form-control"
                    />
                    <ErrorMessage
                      name="account_name"
                      component="div"
                      className="hasError"
                    />
                  </div>
                  <div className="relative group">
                    <label htmlFor="ucm_id" className="form-label">
                      UCM Id
                    </label>
                    <Field
                      id="ucm_id"
                      name="ucm_id"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="form-control"
                    />
                    <ErrorMessage
                      name="ucm_id"
                      component="div"
                      className="hasError"
                    />
                  </div>

                  <div className="relative group">
                    <label htmlFor="omniscan" className="form-label">
                      Omniscan 10ml vial Qty
                    </label>
                    <Field
                      id="omniscan"
                      name="omniscan"
                      type="number"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="form-control"
                    />
                    <ErrorMessage
                      name="omniscan"
                      component="div"
                      className="hasError"
                    />
                  </div>

                  <div className="relative group">
                    <label htmlFor="clariscan" className="form-label">
                      Clariscan 10ml vial Qty
                    </label>
                    <Field
                      id="clariscan"
                      name="clariscan"
                      type="number"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="form-control"
                    />
                    <ErrorMessage
                      name="clariscan"
                      component="div"
                      className="hasError"
                    />
                  </div>

                  <div className="relative group">
                    <label htmlFor="date" className="form-label">
                      Select Month
                    </label>

                    <Field
                      as="select"
                      className="form-control"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="selectedMonth"
                      name="selectedMonth"
                    >
                      <option disabled value="">
                        Select
                      </option>
                      {moment("2024/09/24", "YYYY/MM/DD").isAfter(moment()) ? (
                        <>
                          <option>
                            {moment(new Date())
                              .subtract(1, "months")
                              .endOf("month")
                              .format("MMMM YYYY")}
                          </option>
                          <option>
                            {moment(new Date()).format("MMMM YYYY")}
                          </option>
                        </>
                      ) : (
                        <>
                          {new Date().getDate() < 11 && (
                            <option>
                              {moment(new Date())
                                .subtract(1, "months")
                                .endOf("month")
                                .format("MMMM YYYY")}
                            </option>
                          )}
                          <option>
                            {moment(new Date()).format("MMMM YYYY")}
                          </option>
                        </>
                      )}
                    </Field>
                    <ErrorMessage
                      name="selectedMonth"
                      component="div"
                      className="hasError"
                    />
                  </div>

                  <div className="relative group">
                    <label className="inline-flex">
                      <Field
                        type="checkbox"
                        id="accept"
                        name="accept"
                        className="rounded border-gray-300 text-theme-blue-600 shadow-sm focus:border-blue-300 focus:ring focus:ring-offset-0 focus:ring-theme-blue-200 focus:ring-opacity-50 mt-0"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <span className="ml-2 text-sm">
                        No secondary since last 6 months
                      </span>
                    </label>
                    <ErrorMessage
                      name="accept"
                      component="div"
                      className="hasError"
                    />
                  </div>

                  <button
                    type="submit"
                    className="btn w-full"
                    disabled={!(isValid && dirty)}
                  >
                    Submit
                  </button>
                </Form>
              </>
            )}
          </Formik>
        </div>
        <div className="bg-white p-4 lg:p-8 w-full shadow rounded overflow-auto lg:col-span-2">
          {currentActivity.details.length > 0 ? (
            <>
              <Activity1
                item={currentActivity}
                activity_id={currentActivity.activity_id}
                actionDeleteEntry={actionDeleteEntry}
              />
            </>
          ) : (
            <NoDataFound />
          )}
        </div>
      </div>
    </div>
  );
};

export default CheckmateNewIbConversions;
