import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext";

const PageNotFound = () => {
  const navigate = useNavigate();
  const { setIsLoading, setAuthUser } = useAuthContext();
  const refresh = () => {
    setIsLoading(true);
    localStorage.removeItem("authUser");
    setAuthUser(null);
    navigate("/");
    setIsLoading(false);
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
  };
  return (
    <div className="bg-gradient-to-br from-theme-yellow-500 to-theme-yellow-100/5 w-full min-h-screen flex items-center justify-center">
      <div className="space-y-4 flex flex-col items-start">
        <h4 className="text-white font-bold text-4xl">
          404 Error Oops!
          <br />
          The page not found
        </h4>
        <p className="font-semibold text-lg">
          The link you followed os broken
          <br /> or the page isn't exist
        </p>
        {/* <Link to="/" className="btn">
          Go to HomePage
        </Link> */}
        <button className="btn" onClick={refresh}>
          Go to HomePage
        </button>
      </div>
    </div>
  );
};

export default PageNotFound;
