import React from "react";
import { IoMenuSharp } from "react-icons/io5";
import { useAuthContext } from "../context/AuthContext";
import Logo from "./../assets/Asset 1.png";
import { IoLogOutOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
const Header = () => {
  const { iscollapsed, setIscollapsed, actionLogout } = useAuthContext();
  return (
    <div className="flex shadow bg-white px-4 py-2 h-16 sticky top-0 z-10">
      <div className="flex items-center gap-4 text-slate-800">
        <button
          type="button"
          className="text-2xl"
          onClick={(e) => setIscollapsed(!iscollapsed)}
        >
          <IoMenuSharp />
        </button>
        {(window.innerWidth < 1025 || iscollapsed) && (
          <Link to="/" className="h-full">
            <img src={Logo} alt="triwizard logo" className="h-full" />
          </Link>
        )}
      </div>
      <button
        type="button"
        className="text-2xl ml-auto text-red-600"
        onClick={actionLogout}
      >
        <IoLogOutOutline />
      </button>
    </div>
  );
};

export default Header;
