import React from "react";

const Loader = () => {
  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-full z-20 bg-white/80 flex items-center justify-center">
      <div className="loaderAnimation">Loading...</div>
    </div>
  );
};

export default Loader;
