import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { toast } from "react-toastify";
import { useAuthContext } from "./AuthContext";
import moment from "moment";
import { apiService } from "../services/apiService";
import usePrevious from "../hooks/usePrevious";
const ClientContext = createContext();

export const ClientState = (props) => {
  const [allActivities, setAllActivities] = useState([]);
  const [response, setResponse] = useState({});
  const { authUser, setIsLoading } = useAuthContext();
  const [allScore, setAllScore] = useState({});
  const [monthlyScore, setMonthlyScore] = useState({});
  const [percentage, setpercentage] = useState(0);
  const [showBadge, setShowBadge] = useState({
    show: false,
    badge: null,
  });
  const prevCount = usePrevious(percentage);

  const valData = useMemo(() => {
    return {
      user_name: authUser.user.user_name,
      request_date: moment(new Date()).format("YYYY-MM-DD"),
      token: authUser.token,
    };
  }, [authUser.user.user_name, authUser.token]);

  const actionGetActivities = useCallback(async () => {
    setIsLoading(true);
    try {
      const resp = await apiService.post("", {
        operation: "get_my_activities",
        ...valData,
      });
      if (resp.data.status === 200) {
        setResponse(resp.data);
        setAllActivities(resp.data.activities);
        setAllScore({
          current_score: resp.data.current_score,
          target_score: resp.data.target_score,
        });
        // setMonthlyScore(resp.data.years);
      }

      setIsLoading(false);
    } catch (error) {
      toast.error(error.message);
      console.log(error);
      setIsLoading(false);
    }
  }, [valData, setIsLoading]);

  const actionDeleteEntry = async (val) => {
    let comfirmation = window.confirm(
      "Are you sure want to delete this activity?"
    );
    if (comfirmation) {
      setIsLoading(true);
      try {
        const resp = await apiService.post("", {
          operation: "delete_activity",
          activity_id: val.activity_id,
          record_id: val.id,
          ...valData,
        });
        if (resp.data.status === 200) {
          actionGetActivities();
          toast.success("Activity deleted successfully!");
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        toast.error(error.message);
        console.log(error);
      }
    }
  };

  const submitActivity = async (val) => {
    let comfirmation = window.confirm(
      "Are you sure want to submit this activity?"
    );
    if (comfirmation) {
      setIsLoading(true);
      try {
        if (val.activity_id === "activity_1") {
          const resp = await apiService.post(
            "",
            {
              operation: "save_activity",
              activity_id: val.activity_id,
              ...valData,
              activity_parameters: {
                account_name: val.account_name,
                ucm_id: val.ucm_id,
                omniscan: val.omniscan,
                clariscan: val.clariscan,
                month: moment(val.selectedMonth, "MMMM YYYY").format("M"),
                year: moment(val.selectedMonth, "MMMM YYYY").format("Y"),
                agreement: val.accept,
              },
            },
            {
              headers: { Authorization: "Bearer " + authUser.token },
            }
          );
          if (resp.data.status === 200) {
            actionGetActivities();
            toast.success("Activity added successfully!");
          }
        }
        if (val.activity_id === "activity_2") {
          const resp = await apiService.post(
            "",
            {
              operation: "save_activity",
              ...valData,
              activity_id: val.activity_id,
              activity_parameters: {
                account_name: val.account_name,
                ucm_id: val.ucm_id,
                omniscan: val.omniscan,
                clariscan: val.clariscan,
                month: moment(val.selectedMonth, "MMMM YYYY").format("M"),
                year: moment(val.selectedMonth, "MMMM YYYY").format("Y"),
                agreement: val.accept,
              },
            },
            {
              headers: { Authorization: "Bearer " + authUser.token },
            }
          );
          if (resp.data.status === 200) {
            actionGetActivities();
            toast.success("Activity added successfully!");
          }
        }
        if (val.activity_id === "activity_3") {
          const resp = await apiService.post(
            "",
            {
              operation: "save_activity",
              ...valData,
              activity_id: val.activity_id,
              activity_parameters: {
                account_name: val.account_name,
                ucm_id: val.ucm_id,
                omniscan: val.omniscan,
                clariscan: val.clariscan,
                month: moment(val.selectedMonth, "MMMM YYYY").format("M"),
                year: moment(val.selectedMonth, "MMMM YYYY").format("Y"),
              },
            },
            {
              headers: { Authorization: "Bearer " + authUser.token },
            }
          );
          if (resp.data.status === 200) {
            actionGetActivities();
            toast.success("Activity added successfully!");
          }
        }
        if (val.activity_id === "activity_4") {
          let imageUrls = [];
          if (val.photo.length > 0) {
            const requests = val.photo.map((imageFile) => {
              const formData = new FormData();
              formData.append("upload_file", imageFile);
              return apiService.post(`/file_upload.php`, formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              });
            });
            const responses = await Promise.all(requests);
            responses.forEach((item) => {
              imageUrls.push(item.data.filename);
            });
          } else {
            imageUrls = [];
          }
          const resp = await apiService.post(
            "",
            {
              operation: "save_activity",
              ...valData,
              activity_id: val.activity_id,
              activity_parameters: {
                account_name: val.account_name,
                ucm_id: val.ucm_id,
                image_urls: imageUrls,
                month: moment(val.selectedMonth, "MMMM YYYY").format("M"),
                year: moment(val.selectedMonth, "MMMM YYYY").format("Y"),
              },
            },
            {
              headers: { Authorization: "Bearer " + authUser.token },
            }
          );
          if (resp.data.status === 200) {
            actionGetActivities();
            toast.success("Activity added successfully!");
          }
        }
        if (val.activity_id === "activity_5") {
          let imageUrls = [];
          if (val.photo.length > 0) {
            const requests = val.photo.map((imageFile) => {
              const formData = new FormData();
              formData.append("upload_file", imageFile);
              return apiService.post(`/file_upload.php`, formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              });
            });
            const responses = await Promise.all(requests);
            responses.forEach((item) => {
              imageUrls.push(item.data.filename);
            });
          } else {
            imageUrls = [];
          }
          const resp = await apiService.post(
            "",
            {
              operation: "save_activity",
              ...valData,
              activity_id: val.activity_id,
              activity_parameters: {
                account_name: val.account_name,
                ucm_id: val.ucm_id,
                image_urls: imageUrls,
                month: moment(val.selectedMonth, "MMMM YYYY").format("M"),
                year: moment(val.selectedMonth, "MMMM YYYY").format("Y"),
              },
            },
            {
              headers: { Authorization: "Bearer " + authUser.token },
            }
          );
          if (resp.data.status === 200) {
            console.log(resp.data, "200");
            actionGetActivities();
            toast.success("Activity added successfully!");
          }
          if (resp.data.status === 300) {
            console.log(resp.data, "300");
            actionGetActivities();
            toast.warn("You have already saved your activity for this month!");
          }
        }
        // if (val.activity_id === "activity_6") {
        //   const resp = await apiService.post(
        //     "",
        //     {
        //       operation: "save_activity",
        //       ...valData,
        //       activity_id: val.activity_id,
        //       activity_parameters: {
        //         achievement_number: val.achievement_number,
        //         month: moment(val.selectedMonth, "MMMM YYYY").format("M"),
        //         year: moment(val.selectedMonth, "MMMM YYYY").format("Y"),
        //       },
        //     },
        //     {
        //       headers: { Authorization: "Bearer " + authUser.token },
        //     }
        //   );
        //   if (resp.data.status === 200) {
        //     actionGetActivities();
        //     toast.success("Activity added successfully!");
        //   }
        //   if (resp.data.status === 300) {
        //     actionGetActivities();
        //     toast.warn("You have already saved your activity for this month!");
        //   }
        // }
        // if (val.activity_id === "activity_7") {
        //   const resp = await apiService.post(
        //     "",
        //     {
        //       operation: "save_activity",
        //       ...valData,
        //       activity_id: val.activity_id,
        //       activity_parameters: {
        //         account_name: val.account_name,
        //         technologist: val.technologist,
        //         month: moment(val.selectedMonth, "MMMM YYYY").format("M"),
        //         year: moment(val.selectedMonth, "MMMM YYYY").format("Y"),
        //       },
        //     },
        //     {
        //       headers: { Authorization: "Bearer " + authUser.token },
        //     }
        //   );
        //   if (resp.data.status === 200) {
        //     actionGetActivities();
        //     toast.success("Activity added successfully!");
        //   }
        // }
        // if (val.activity_id === "activity_8") {
        //   let imageUrls = [];
        //   if (val.photo.length > 0) {
        //     const requests = val.photo.map((imageFile) => {
        //       const formData = new FormData();
        //       formData.append("upload_file", imageFile);
        //       return apiService.post(`/file_upload.php`, formData, {
        //         headers: {
        //           "Content-Type": "multipart/form-data",
        //         },
        //       });
        //     });
        //     const responses = await Promise.all(requests);
        //     responses.forEach((item) => {
        //       imageUrls.push(item.data.filename);
        //     });
        //   } else {
        //     imageUrls = [];
        //   }
        //   const resp = await apiService.post(
        //     "",
        //     {
        //       operation: "save_activity",
        //       ...valData,
        //       activity_id: val.activity_id,
        //       activity_parameters: {
        //         institute_name: val.institute_name,
        //         month: moment(val.selectedMonth, "MMMM YYYY").format("M"),
        //         year: moment(val.selectedMonth, "MMMM YYYY").format("Y"),
        //         image_urls: imageUrls,
        //         agreement: val.accept,
        //       },
        //     },
        //     {
        //       headers: { Authorization: "Bearer " + authUser.token },
        //     }
        //   );
        //   if (resp.data.status === 200) {
        //     actionGetActivities();
        //     toast.success("Activity added successfully!");
        //   }
        // }
        // if (val.activity_id === "activity_9") {
        //   let imageUrls = [];
        //   if (val.photo.length > 0) {
        //     const requests = val.photo.map((imageFile) => {
        //       const formData = new FormData();
        //       formData.append("upload_file", imageFile);
        //       return apiService.post(`/file_upload.php`, formData, {
        //         headers: {
        //           "Content-Type": "multipart/form-data",
        //         },
        //       });
        //     });
        //     const responses = await Promise.all(requests);
        //     responses.forEach((item) => {
        //       imageUrls.push(item.data.filename);
        //     });
        //   } else {
        //     imageUrls = [];
        //   }
        //   const resp = await apiService.post(
        //     "",
        //     {
        //       operation: "save_activity",
        //       ...valData,
        //       activity_id: val.activity_id,
        //       activity_parameters: {
        //         institute_name: val.institute_name,
        //         month: moment(val.selectedMonth, "MMMM YYYY").format("M"),
        //         year: moment(val.selectedMonth, "MMMM YYYY").format("Y"),
        //         image_urls: imageUrls,
        //         agreement: val.accept,
        //       },
        //     },
        //     {
        //       headers: { Authorization: "Bearer " + authUser.token },
        //     }
        //   );
        //   if (resp.data.status === 200) {
        //     actionGetActivities();
        //     toast.success("Activity added successfully!");
        //   }
        // }
        // if (val.activity_id === "activity_10") {
        //   const resp = await apiService.post(
        //     "",
        //     {
        //       operation: "save_activity",
        //       ...valData,
        //       activity_id: val.activity_id,
        //       activity_parameters: {
        //         doctor_name: val.doctor_name,
        //         email: val.email,
        //         speciality: val.speciality,
        //         month: moment(val.selectedMonth, "MMMM YYYY").format("M"),
        //         year: moment(val.selectedMonth, "MMMM YYYY").format("Y"),
        //         agreement: val.accept,
        //       },
        //     },
        //     {
        //       headers: { Authorization: "Bearer " + authUser.token },
        //     }
        //   );
        //   if (resp.data.status === 200) {
        //     actionGetActivities();
        //     toast.success("Activity added successfully!");
        //   }
        // }
        // if (val.activity_id === "activity_11") {
        //   let imageUrls = [];
        //   if (val.photo.length > 0) {
        //     const requests = val.photo.map((imageFile) => {
        //       const formData = new FormData();
        //       formData.append("upload_file", imageFile);
        //       return apiService.post(`/file_upload.php`, formData, {
        //         headers: {
        //           "Content-Type": "multipart/form-data",
        //         },
        //       });
        //     });
        //     const responses = await Promise.all(requests);
        //     responses.forEach((item) => {
        //       imageUrls.push(item.data.filename);
        //     });
        //   } else {
        //     imageUrls = [];
        //   }
        //   const resp = await apiService.post(
        //     "",
        //     {
        //       operation: "save_activity",
        //       ...valData,
        //       activity_id: val.activity_id,
        //       activity_parameters: {
        //         institute_name: val.institute_name,
        //         month: moment(val.selectedMonth, "MMMM YYYY").format("M"),
        //         year: moment(val.selectedMonth, "MMMM YYYY").format("Y"),
        //         image_urls: imageUrls,
        //         agreement: val.accept,
        //       },
        //     },
        //     {
        //       headers: { Authorization: "Bearer " + authUser.token },
        //     }
        //   );
        //   if (resp.data.status === 200) {
        //     actionGetActivities();
        //     toast.success("Activity added successfully!");
        //   }
        // }
        // if (val.activity_id === "activity_12") {
        //   const resp = await apiService.post(
        //     "",
        //     {
        //       operation: "save_activity",
        //       ...valData,
        //       activity_id: val.activity_id,
        //       activity_parameters: {
        //         month: moment(val.selectedMonth, "MMMM YYYY").format("M"),
        //         year: moment(val.selectedMonth, "MMMM YYYY").format("Y"),
        //         agreement: val.accept,
        //       },
        //     },
        //     {
        //       headers: { Authorization: "Bearer " + authUser.token },
        //     }
        //   );
        //   if (resp.data.status === 200) {
        //     actionGetActivities();
        //     toast.success("Activity added successfully!");
        //   }
        //   if (resp.data.status === 300) {
        //     actionGetActivities();
        //     toast.warn("You have already saved your activity for this month!");
        //   }
        // }
        // if (val.activity_id === "activity_13") {
        //   const resp = await apiService.post(
        //     "",
        //     {
        //       operation: "save_activity",
        //       ...valData,
        //       activity_id: val.activity_id,
        //       activity_parameters: {
        //         account_name: val.account_name,
        //         month: moment(val.selectedMonth, "MMMM YYYY").format("M"),
        //         year: moment(val.selectedMonth, "MMMM YYYY").format("Y"),
        //         agreement: val.accept,
        //       },
        //     },
        //     {
        //       headers: { Authorization: "Bearer " + authUser.token },
        //     }
        //   );
        //   if (resp.data.status === 200) {
        //     actionGetActivities();
        //     toast.success("Activity added successfully!");
        //   }
        // }
        // if (val.activity_id === "activity_14") {
        //   const resp = await apiService.post(
        //     "",
        //     {
        //       operation: "save_activity",
        //       ...valData,
        //       activity_id: val.activity_id,
        //       activity_parameters: {
        //         no_of_kols_engaged: val.no_of_kols_engaged,
        //         month: moment(val.selectedMonth, "MMMM YYYY").format("M"),
        //         year: moment(val.selectedMonth, "MMMM YYYY").format("Y"),
        //         agreement: val.accept,
        //       },
        //     },
        //     {
        //       headers: { Authorization: "Bearer " + authUser.token },
        //     }
        //   );
        //   if (resp.data.status === 200) {
        //     actionGetActivities();
        //     toast.success("Activity added successfully!");
        //   }
        // }
        // if (val.activity_id === "activity_15") {
        //   const resp = await apiService.post(
        //     "",
        //     {
        //       operation: "save_activity",
        //       ...valData,
        //       activity_id: val.activity_id,
        //       activity_parameters: {
        //         month: moment(val.selectedMonth, "MMMM YYYY").format("M"),
        //         year: moment(val.selectedMonth, "MMMM YYYY").format("Y"),
        //         agreement: val.accept,
        //       },
        //     },
        //     {
        //       headers: { Authorization: "Bearer " + authUser.token },
        //     }
        //   );
        //   if (resp.data.status === 200) {
        //     actionGetActivities();
        //     toast.success("Activity added successfully!");
        //   }
        //   if (resp.data.status === 300) {
        //     actionGetActivities();
        //     toast.warn("You have already saved your activity for this month!");
        //   }
        // }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        toast.error(error.message);
        console.log(error);
      }
    }
  };

  useEffect(() => {
    let result =
      (Number(allScore.current_score) / Number(allScore.target_score)) * 100;
    setpercentage(result.toFixed(2));
  }, [allScore.current_score, allScore.target_score, monthlyScore]);

  useEffect(() => {
    actionGetActivities();
  }, [actionGetActivities]);

  // console.log(prevCount, percentage);

  useEffect(() => {
    if (
      prevCount < percentage &&
      prevCount < 25 &&
      percentage >= 25 &&
      percentage < 50
    ) {
      setShowBadge({
        show: true,
        badge: "Bronze",
      });
    }
    if (
      prevCount < percentage &&
      prevCount < 50 &&
      percentage >= 50 &&
      percentage < 75
    ) {
      setShowBadge({
        show: true,
        badge: "Silver",
      });
    }
    if (
      prevCount < percentage &&
      prevCount < 75 &&
      percentage >= 75 &&
      percentage < 100
    ) {
      setShowBadge({
        show: true,
        badge: "Silver",
      });
    }
  }, [prevCount, percentage]);

  return (
    <ClientContext.Provider
      value={{
        allActivities,
        setAllActivities,
        response,
        setResponse,
        submitActivity,
        allScore,
        setAllScore,
        monthlyScore,
        setMonthlyScore,
        percentage,
        actionDeleteEntry,
        showBadge,
        setShowBadge,
      }}
    >
      {props.children}
    </ClientContext.Provider>
  );
};

export const useClientContext = () => {
  return useContext(ClientContext);
};
