import React, { useCallback, useEffect, useState } from "react";
import SubNav from "../../components/SubNav";
import moment from "moment";
import UserImage from "./../../assets/user.png";
import { useAuthContext } from "../../context/AuthContext";
import { apiService } from "../../services/apiService";

const Leaderboard = () => {
  const mockQuarterlyData = [
    {
      quarter: "Q1",
      leaders: {
        TM: {
          name_of_employee: "John Doe",
          photo_url: "",
          current_month_score: 150,
          designation: "Sales Executive",
          role: "TM",
          region: "North",
        },
        KAM_GAM: {
          name_of_employee: "Michael Brown",
          photo_url: "",
          current_month_score: 200,
          designation: "KAM + GAM",
          role: "KAM_GAM",
        },
        AM: {
          name_of_employee: "Emily White",
          photo_url: "",
          current_month_score: 180,
          designation: "Area Manager",
          role: "AM",
        },
      },
    },
    {
      quarter: "Q2",
      leaders: {
        TM: {
          name_of_employee: "Sarah Williams",
          photo_url: "",
          current_month_score: 140,
          designation: "Sales Executive",
          role: "TM",
          region: "East",
        },
        KAM_GAM: {
          name_of_employee: "Robert Taylor",
          photo_url: "",
          current_month_score: 210,
          designation: "KAM + GAM",
          role: "KAM_GAM",
        },
        AM: {
          name_of_employee: "Jessica Thompson",
          photo_url: "",
          current_month_score: 175,
          designation: "Area Manager",
          role: "AM",
        },
      },
    },
    {
      quarter: "Q3",
      leaders: {
        TM: {
          name_of_employee: "Sarah Williams",
          photo_url: "",
          current_month_score: 140,
          designation: "Sales Executive",
          role: "TM",
          region: "East",
        },
        KAM_GAM: {
          name_of_employee: "Robert Taylor",
          photo_url: "",
          current_month_score: 210,
          designation: "KAM + GAM",
          role: "KAM_GAM",
        },
        AM: {
          name_of_employee: "Jessica Thompson",
          photo_url: "",
          current_month_score: 175,
          designation: "Area Manager",
          role: "AM",
        },
      },
    },
    {
      quarter: "Q4",
      leaders: {
        TM: {
          name_of_employee: "Sarah Williams",
          photo_url: "",
          current_month_score: 140,
          designation: "Sales Executive",
          role: "TM",
          region: "East",
        },
        KAM_GAM: {
          name_of_employee: "Robert Taylor",
          photo_url: "",
          current_month_score: 210,
          designation: "KAM + GAM",
          role: "KAM_GAM",
        },
        AM: {
          name_of_employee: "Jessica Thompson",
          photo_url: "",
          current_month_score: 175,
          designation: "Area Manager",
          role: "AM",
        },
      },
    },
  ];

  const [leaders, setLeaders] = useState([]);
  const [winner, setWinner] = useState([]);
  const { authUser, setIsLoading } = useAuthContext();

  const [quartlyLeader, setQuartlyLeader] = useState([]);

  const fetchQuartlyLeaders = useCallback(async () => {
    setQuartlyLeader(mockQuarterlyData);
    setIsLoading(false);
  }, [setIsLoading]);

  const fetchLeaders = useCallback(async () => {
    setIsLoading(true);
    try {
      const resp = await apiService.post("", {
        operation: "leaderboard",
        token: authUser.token,
        user_name: authUser.user.user_name,
        request_date: moment(new Date()).format("YYYY-MM-DD"),
      });
      if (resp.data.status === 200) {
        setLeaders(resp.data.leaderboard);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  }, [setIsLoading, authUser.token, authUser.user.user_name]);

  const fetchWinner = useCallback(async () => {
    setIsLoading(true);
    try {
      const resp = await apiService.post("", {
        operation: "admin_report",
        token: authUser.token,
        user_name: authUser.user.user_name,
        request_date: moment(new Date()).format("YYYY-MM-DD"),
      });
      if (resp.data.status === 200) {
        let result = [];
        let allTm = resp.data.users.filter((item) => item.role === "TM");
        let uniqueTM = [...new Set(allTm.map((item) => item.region))];
        if (uniqueTM.length) {
          uniqueTM.forEach((item) => {
            let filterObj = allTm.filter((i) => i.region === item);
            let sortObj = filterObj.sort(
              (a, b) => a.total_score - b.total_score
            )[filterObj.length - 1];
            result.push(sortObj);
          });
        }
        let allKamGam = resp.data.users.filter(
          (item) => item.role === "KAM_GAM"
        );
        if (allKamGam.length) {
          let sortObj = allKamGam.sort((a, b) => a.total_score - b.total_score)[
            allKamGam.length - 1
          ];
          result.push(sortObj);
        }
        let allAm = resp.data.users.filter((item) => item.role === "AM");
        if (allAm.length) {
          let sortObj = allAm.sort((a, b) => a.total_score - b.total_score)[
            allAm.length - 1
          ];
          result.push(sortObj);
        }
        setWinner(result);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  }, [setIsLoading, authUser.token, authUser.user.user_name]);

  useEffect(() => {
    fetchLeaders();
    fetchWinner();
    fetchQuartlyLeaders();
  }, [fetchLeaders, fetchWinner, fetchQuartlyLeaders]);
  console.log(quartlyLeader);

  return (
    <>
      <div className="w-full">
        <SubNav title="Top TriWizards as of YTD" />
        <div className="bg-white p-4 rounded shadow space-y-4 mb-8">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4 lg:gap-6 xl:gap-8">
            {winner.length > 0 &&
              winner.map((item, index) => {
                return (
                  <div key={index} className=" col-span-1 relative ">
                    <div className="bg-gradient-to-r from-theme-yellow-500 to-purple-500 text-white px-2 py-1 absolute top-0 right-0 text-sm shadow-lg">
                      {item.role === "AM" && item.designation}
                      {item.role === "KAM_GAM" && "KAM + GAM"}
                      {item.role === "TM" && item.region}
                    </div>
                    <div className="w-4 overflow-hidden inline-block absolute top-7 right-0">
                      <div className="h-6 bg-theme-yellow-600 rotate-45 transform origin-top-right"></div>
                    </div>
                    <div className="flex items-center justify-center px-4 overflow-hidden">
                      {item.photo_url ? (
                        <img
                          src={item.photo_url}
                          alt={item.name_of_employee}
                          className="w-full bg-gray-200"
                        />
                      ) : (
                        <img
                          src={UserImage}
                          alt={item.name_of_employee}
                          className="w-full bg-gray-200"
                        />
                      )}
                    </div>
                    <div className="text-center bg-gradient-to-r from-theme-yellow-500 to-purple-500 text-white relative shadow-lg">
                      <div className="w-4 overflow-hidden inline-block absolute -top-6 left-0">
                        <div className="h-6 bg-theme-yellow-600 rotate-45 transform origin-bottom-left"></div>
                      </div>
                      <div className="p-2">
                        <h4 className="font-semibold leading-5">
                          {item.name_of_employee}
                        </h4>

                        <p className="leading-5 text-sm">
                          Points: {item.total_score}
                        </p>
                      </div>
                      <div className="leading-5 p-2 bg-gradient-to-r from-zinc-400 to-zinc-600">
                        {item.designation}
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>

        {/* <SubNav
          title="Monthly Leaderboard"
          desc="Monthly Announcement along with Price Trendsetters will be done on the 11th of every month"
        />
        {leaders.length > 0 &&
          leaders.map((year, yearIndex) => {
            return (
              <div key={yearIndex}>
                <div className="flex gap-4 flex-col">
                  {year.months.length > 0
                    ? year.months.map((month, monthIndex) => {
                        return (
                          <div key={monthIndex}>
                            <div className="bg-white p-4 rounded shadow space-y-4">
                              <div>
                                <span className="bg-theme-yellow text-white py-1 px-4 rounded-sm">
                                  top TriWizards for{" "}
                                  {moment(
                                    new Date().setMonth(month.month - 1)
                                  ).format("MMMM")}{" "}
                                  month
                                </span>
                              </div>
                              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4 lg:gap-6 xl:gap-8">
                                {month.TM.map((tm, tmIndex) => {
                                  return (
                                    <div
                                      key={tmIndex}
                                      className=" col-span-1 relative "
                                    >
                                      <div className="bg-gradient-to-r from-theme-yellow-500 to-yellow-500 text-white px-2 py-1 absolute top-0 right-0 text-sm shadow-lg">
                                        {tm.role === "TM" && tm.region}
                                      </div>
                                      <div className="w-4 overflow-hidden inline-block absolute top-7 right-0">
                                        <div className="h-6 bg-theme-yellow-600 rotate-45 transform origin-top-right"></div>
                                      </div>
                                      <div className="flex items-center justify-center px-4 overflow-hidden">
                                        {tm.photo_url ? (
                                          <img
                                            src={tm.photo_url}
                                            alt={tm.name_of_employee}
                                            className="w-full bg-gray-200"
                                          />
                                        ) : (
                                          <img
                                            src={UserImage}
                                            alt={tm.name_of_employee}
                                            className="w-full bg-gray-200"
                                          />
                                        )}
                                      </div>
                                      <div className="text-center bg-gradient-to-r from-theme-yellow-500 to-yellow-500 text-white relative shadow-lg">
                                        <div className="w-4 overflow-hidden inline-block absolute -top-6 left-0">
                                          <div className="h-6 bg-theme-yellow-600 rotate-45 transform origin-bottom-left"></div>
                                        </div>
                                        <div className="p-2">
                                          <h4 className="font-semibold leading-5">
                                            {tm.name_of_employee}
                                          </h4>

                                          <p className="leading-5 text-sm">
                                            Points: {tm.current_month_score}
                                          </p>
                                        </div>
                                        <div className="leading-5 p-2 bg-gradient-to-r from-zinc-400 to-zinc-600">
                                          {tm.designation}
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                                {month.KAM_GAM && (
                                  <div className=" col-span-1 relative ">
                                    <div className="bg-gradient-to-r from-theme-yellow-500 to-yellow-500 text-white px-2 py-1 absolute top-0 right-0 text-sm shadow-lg">
                                      {month.KAM_GAM.role === "KAM_GAM" &&
                                        "KAM + GAM"}
                                    </div>
                                    <div className="w-4 overflow-hidden inline-block absolute top-7 right-0">
                                      <div className="h-6 bg-theme-yellow-600 rotate-45 transform origin-top-right"></div>
                                    </div>
                                    <div className="flex items-center justify-center px-4 overflow-hidden">
                                      {month.KAM_GAM.photo_url ? (
                                        <img
                                          src={month.KAM_GAM.photo_url}
                                          alt={month.KAM_GAM.name_of_employee}
                                          className="w-full bg-gray-200"
                                        />
                                      ) : (
                                        <img
                                          src={UserImage}
                                          alt={month.KAM_GAM.name_of_employee}
                                          className="w-full bg-gray-200"
                                        />
                                      )}
                                    </div>
                                    <div className="text-center bg-gradient-to-r from-theme-yellow-500 to-yellow-500 text-white relative shadow-lg">
                                      <div className="w-4 overflow-hidden inline-block absolute -top-6 left-0">
                                        <div className="h-6 bg-theme-yellow-600 rotate-45 transform origin-bottom-left"></div>
                                      </div>
                                      <div className="p-2">
                                        <h4 className="font-semibold leading-5">
                                          {month.KAM_GAM.name_of_employee}
                                        </h4>

                                        <p className="leading-5 text-sm">
                                          Points:{" "}
                                          {month.KAM_GAM.current_month_score}
                                        </p>
                                      </div>
                                      <div className="leading-5 p-2 bg-gradient-to-r from-zinc-400 to-zinc-600">
                                        {month.KAM_GAM.designation}
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {month.AM && (
                                  <div className=" col-span-1 relative ">
                                    <div className="bg-gradient-to-r from-theme-yellow-500 to-yellow-500 text-white px-2 py-1 absolute top-0 right-0 text-sm shadow-lg">
                                      {month.AM.role === "AM" &&
                                        month.AM.designation}
                                    </div>
                                    <div className="w-4 overflow-hidden inline-block absolute top-7 right-0">
                                      <div className="h-6 bg-theme-yellow-600 rotate-45 transform origin-top-right"></div>
                                    </div>
                                    <div className="flex month.AMs-center justify-center px-4 overflow-hidden">
                                      {month.AM.photo_url ? (
                                        <img
                                          src={month.AM.photo_url}
                                          alt={month.AM.name_of_employee}
                                          className="w-full bg-gray-200"
                                        />
                                      ) : (
                                        <img
                                          src={UserImage}
                                          alt={month.AM.name_of_employee}
                                          className="w-full bg-gray-200"
                                        />
                                      )}
                                    </div>
                                    <div className="text-center bg-gradient-to-r from-theme-yellow-500 to-yellow-500 text-white relative shadow-lg">
                                      <div className="w-4 overflow-hidden inline-block absolute -top-6 left-0">
                                        <div className="h-6 bg-theme-yellow-600 rotate-45 transform origin-bottom-left"></div>
                                      </div>
                                      <div className="p-2">
                                        <h4 className="font-semibold leading-5">
                                          {month.AM.name_of_employee}
                                        </h4>

                                        <p className="leading-5 text-sm">
                                          Points: {month.AM.current_month_score}
                                        </p>
                                      </div>
                                      <div className="leading-5 p-2 bg-gradient-to-r from-zinc-400 to-zinc-600">
                                        {month.AM.designation}
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      })
                    : "Monthly Leaderbord is not generated"}
                </div>
              </div>
            );
          })} */}
      </div>
    </>
  );
};

export default Leaderboard;
