import React from "react";
import SubNav from "../../components/SubNav";

const Eligibility = () => {
  return (
    <>
      <SubNav title="Eligibility" />
      <div className="bg-white rounded shadow p-4">
        <ol className="list-decimal space-y-4 pl-4">

          <li>This is a campaign for sales team where the idea is to provide points to the salespeople
              based on the various categories.</li>

          <li>The calculation will be based on below mentioned explanation.
              <br/>-- Quarterly awards for (TM, GAM+KAM and AM+BM)
              <br/>-- TM (Highest points within the assigned region will be awarded as MR WAZIR in every
                quarter)<bold> 4 awards every quarter for 4 assigned regions.</bold> 
              <br/>--  KAM+GAM (1 person having highest points in quarter will be awarded as MR WAZIR in every
                quarter) <bold> 1 award every quarter.</bold>
              <br/>-- AM+BM (1 person having highest points in quarter will receive the award based on average
                score of their teams (TM)). 1 award every quarter.
          </li>

          <li>
              A dashboard is required for the salesforce and management to see the points on an
              individual basis and on aggregate basis.<bold> Leaderboard</bold>
          </li>
          <li>There is a winner that needs to be decided per quarter across the 4 regions based on the
              aggregation of points. There is also a winner that will be decided on an annual basis based
              on highest points among TM categories.</li>
          <li>
              The data for the sales input across the 5 categories will be provided in an excel version. The
              app will need to do the calculation and update the dashboard for the 60 salespeople who
              are there under GE.
          </li>
          <li>
              The app will be required in both iOS and Android.
          </li>
          <li>
              A TM can only view his points and the points for his team. An AM+BM can view the points
              across the organization.
          </li>
          <li>
              In this app salesperson will put his name, category, Account name, SKU name, Quantity and
              SFDC ID. it will be only used for calculation and dashboard viewing.
          </li>


        </ol>
      </div>
    </>
  );
};

export default Eligibility;
