import React, { useState } from "react";
import SubNav from "../../components/SubNav";
import { useAuthContext } from "../../context/AuthContext";
import { IoPersonCircleSharp } from "react-icons/io5";
import PicModal from "../../components/PicModal";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";

const Profile = () => {
  const { authUser, actionChangePassword } = useAuthContext();
  const validationSchema = Yup.object().shape({
    dummy: Yup.string(),
    old_password: Yup.string().when("dummy", {
      is: () => authUser.role === "admin",
      then: Yup.string(),
      otherwise: Yup.string().required("Old password is required"),
    }),

    new_password: Yup.string().required("New password is required"),
  });
  const [show, setShow] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [showPassNew, setShowPassNew] = useState(false);

  return (
    <>
      <SubNav title="Profile" />
      <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 mb-8">
        <div className="bg-white p-4 lg:p-8 w-full shadow rounded space-y-4">
          <div className="max-w-xs mx-auto">
            <div className="w-56 h-56 bg-zinc-200 flex items-center justify-center mx-auto border-dashed border-2 border-slate-500">
              {authUser?.user?.photo_url ? (
                <img src={authUser.user.photo_url} alt="user" />
              ) : (
                <IoPersonCircleSharp className="text-9xl text-theme-yellow-200" />
              )}
            </div>
          </div>
          <div className="text-center">
            <button
              type="button"
              className="btn"
              onClick={(e) => setShow(true)}
            >
              Upload picture
            </button>
          </div>
        </div>
        <div className="bg-white p-4 lg:p-8 md:col-span-1 lg:col-span-2  xl:col-span-2 shadow rounded">
          <div className="space-y-3">
            <div className="grid md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-3 gap-4">
              <div className="relative group">
                <label htmlFor="name" className="form-label">
                  Full name
                </label>
                <input
                  id="name"
                  name="name"
                  className="form-control"
                  disabled
                  value={authUser.user.name_of_employee}
                />
              </div>
              <div className="relative group">
                <label htmlFor="email" className="form-label">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="form-control"
                  disabled
                  value={authUser.user.user_name}
                />
              </div>
              <div className="relative group">
                <label htmlFor="mobile" className="form-label">
                  Mobile
                </label>
                <input
                  type="number"
                  id="mobile"
                  name="mobile"
                  className="form-control"
                  disabled
                  value={authUser.user.mobile_no}
                />
              </div>
              <div className="relative group">
                <label htmlFor="region" className="form-label">
                  Region
                </label>
                <input
                  id="region"
                  name="region"
                  className="form-control"
                  disabled
                  value={authUser.user.region}
                />
              </div>
              <div className="relative group">
                <label htmlFor="designation" className="form-label">
                  Designation
                </label>
                <input
                  id="designation"
                  name="designation"
                  className="form-control"
                  disabled
                  value={authUser.user.designation}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <SubNav title="Change password" />
      <div className="bg-white p-4 lg:p-8 shadow rounded space-y-4 w-full lg:w-1/2">
        <Formik
          initialValues={{
            old_password: "",
            new_password: "",
          }}
          validationSchema={validationSchema}
          onSubmit={(val, { resetForm }) => {
            actionChangePassword(val);
            resetForm();
          }}
        >
          {({
            handleBlur,
            handleSubmit,
            errors,
            isValid,
            dirty,
            handleChange,
          }) => (
            <>
              <Form className="space-y-3" onSubmit={handleSubmit}>
                {authUser.role !== "admin" && (
                  <div className="relative group">
                    <label htmlFor="old_password" className="form-label">
                      Old password
                    </label>
                    <div className="relative w-full">
                      <button
                        type="button"
                        onClick={(e) => setShowPass(!showPass)}
                        className="absolute right-0 top-0 bottom-0 px-2"
                      >
                        {showPass ? <IoEyeOutline /> : <IoEyeOffOutline />}
                      </button>
                      <Field
                        id="old_password"
                        name="old_password"
                        type={showPass ? "text" : "password"}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="form-control"
                        style={{ paddingRight: "30px" }}
                      />
                    </div>
                    <ErrorMessage
                      name="old_password"
                      component="div"
                      className="hasError"
                    />
                  </div>
                )}

                <div className="relative group">
                  <label htmlFor="new_password" className="form-label">
                    New password
                  </label>
                  <div className="relative w-full">
                    <button
                      type="button"
                      onClick={(e) => setShowPassNew(!showPassNew)}
                      className="absolute right-0 top-0 bottom-0 px-2"
                    >
                      {showPassNew ? <IoEyeOutline /> : <IoEyeOffOutline />}
                    </button>
                    <Field
                      type={showPassNew ? "text" : "password"}
                      id="new_password"
                      name="new_password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="form-control"
                    />
                  </div>
                  <ErrorMessage
                    name="new_password"
                    component="div"
                    className="hasError"
                  />
                </div>
                <button
                  type="submit"
                  className="btn w-full"
                  disabled={!(isValid && dirty)}
                >
                  Sign in
                </button>
              </Form>
            </>
          )}
        </Formik>
      </div>
      <PicModal show={show} setShow={setShow} />
    </>
  );
};

export default Profile;
