import React, { useCallback, useEffect, useState } from "react";
import { useAuthContext } from "../../context/AuthContext";
import { apiService } from "../../services/apiService";
import Select from "react-select";
import moment from "moment";
import * as XLSX from "xlsx";
import SubNav from "../../components/SubNav";

const MonthlyActivityReport = () => {
  const { authUser, setIsLoading } = useAuthContext();
  const [data, setData] = useState([]);
  const [selectMonth, setSelectMonth] = useState(null);
  const [actTitle, setActTitle] = useState([]);
  const [monthObj, setMonthObj] = useState([]);

  const [monthData, setMonthData] = useState([
    `2022_${new Date().getMonth() + 1 - 1}`,
  ]);

  const getReport = useCallback(async () => {
    setIsLoading(true);
    const resp = await apiService.post("", {
      operation: "leaderboard_all_users_report",
      user_name: authUser.user.user_name,
      request_date: moment(new Date()).format("YYYY-MM-DD"),
      token: authUser.token,
      months: monthData.length
        ? monthData
        : [`2022_${new Date().getMonth() + 1 - 1}`],
    });
    if ((resp.data.status = 200)) {
      setData(resp.data.leaderboard);
      setActTitle(resp.data.activities);
    }
    setIsLoading(false);
  }, [setIsLoading, authUser.token, authUser.user.user_name, monthData]);
  useEffect(() => {
    getReport();
  }, [getReport]);

  const onOptionChange = (options) => {
    setSelectMonth(options);
  };

  const handleSubmit = () => {
    const gelOnlyVal = selectMonth.map((m) => m.value);
    const getOnlyLable = selectMonth.map((m) => m.label);
    setMonthData(gelOnlyVal);
    setMonthObj(getOnlyLable);
  };

  const monthList = [
    { value: "2022_4", label: "April 2022" },
    { value: "2022_5", label: "May 2022" },
    { value: "2022_6", label: "June 2022" },
    { value: "2022_7", label: "July 2022" },
    { value: "2022_8", label: "August 2022" },
    { value: "2022_9", label: "September 2022" },
    { value: "2022_10", label: "October 2022" },
    { value: "2022_11", label: "November 2022" },
    { value: "2022_12", label: "December 2022" },
    { value: "2022_13", label: "January 2023" },
    { value: "2022_14", label: "February 2023" },
  ];

  const handleExport = () => {
    var elt = document.getElementById("table-to-xls");
    var wb = XLSX.utils.table_to_book(elt, { sheet: "sheet1" });
    XLSX.writeFile(wb, `triwizard-activities.xlsx`);
  };

  const calcTotal = (objData) => {
    let total = 0;
    for (let value in objData) {
      total += objData[value];
    }
    return total;
  };
  return (
    <div className="bg-white rounded shadow p-4  w-full text-sm mb-4">
      <div className="flex items-center mb-4 gap-4">
        <Select
          closeMenuOnSelect={false}
          isMulti
          name="colors"
          options={monthList}
          className="basic-multi-select"
          classNamePrefix="select"
          placeholder="Select months..."
          onChange={onOptionChange}
        />
        <button className="btn" onClick={handleSubmit}>
          Filter
        </button>
      </div>
      <>
        <SubNav
          title={`${
            monthObj.length > 0
              ? `Showing ${monthObj.join(", ")} month activities report`
              : `Showing ${moment(new Date().getMonth() + 1 - 1, "M").format(
                  "MMMM"
                )} month activities report`
          }`}
        />
        <button onClick={handleExport} className="btn mb-4">
          Download as XLSX
        </button>
        <div className="overflow-auto w-full">
          <table className="relative w-full" id="table-to-xls">
            <thead>
              <tr>
                <th className="align-top sticky top-0">User</th>
                <th className="align-top sticky top-0">Role</th>
                <th className="align-top sticky top-0">Region</th>
                {actTitle.map((title, i) => {
                  return <th key={i}>{title.title}</th>;
                })}
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item.name_of_employee}</td>
                    <td>{item.role}</td>
                    <td>{item.region}</td>
                    {actTitle.map((title, i) => {
                      const existAct =
                        title.activity_id in item.activities_participation;
                      return (
                        <td key={i}>
                          {existAct
                            ? item.activities_participation[title.activity_id]
                            : 0}
                        </td>
                      );
                    })}
                    <td>{calcTotal(item.activities_participation)}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </>
    </div>
  );
};

export default MonthlyActivityReport;
