import React from "react";
import { useAuthContext } from "../context/AuthContext";

const NoDataFound = () => {
  const { authUser } = useAuthContext();
  return (
    <div className="bg-slate-900 p-8 text-center rounded text-white">
      <div className="text-2xl">
        Whoops!{" "}
        <span className="text-gray-500">Looks like there's no data.</span>
      </div>
      {authUser.role !== "admin" && (
        <div className="font-light text-gray-500">
          Try to sumbit this activity to get more points.
        </div>
      )}
    </div>
  );
};

export default NoDataFound;
