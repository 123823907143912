import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import SubNav from "../../../components/SubNav";
import { useAdminContext } from "../../../context/AdminContext";
import { useAuthContext } from "../../../context/AuthContext";
import { apiService } from "../../../services/apiService";
import moment from "moment";
import NoDataFound from "../../../components/NoDataFound";
import Activity1 from "../../modules/checkmate-new-ib-conversions/Activity1";
import Activity2 from "../../modules/no-of-accounts-closed/Activity2";
import Activity3 from "../../modules/radaspire-or-cardioaspire-quiz/Activity3";
import Activity4 from "../../modules/radaspire-mr-webconnect/Activity4";
import Activity5 from "../../modules/vsp-revenue-achievement/Activity5";

const Usermodules = () => {
  const { user_id } = useParams();
  const [userData, setUserData] = useState(null);
  const { authUser, setIsLoading } = useAuthContext();
  const { allUsers } = useAdminContext();
  const [allActivities, setAllActivities] = useState([]);

  const actionGetActivities = useCallback(async () => {
    if (userData) {
      setIsLoading(true);
      try {
        const resp = await apiService.post("", {
          operation: "get_my_activities",
          user_name: userData.user_name,
          request_date: moment(new Date()).format("YYYY-MM-DD"),
          token: authUser.token,
        });
        if (resp.data.status === 200) {
          setAllActivities(resp.data.activities);
        }
        setIsLoading(false);
      } catch (error) {
        toast.error(error.message);
        console.log(error);
        setIsLoading(false);
      }
    }
  }, [setIsLoading, userData, authUser.token]);

  useEffect(() => {
    if (allUsers.length > 0) {
      setUserData(allUsers.find((item) => item.user_id === user_id));
    }
  }, [allUsers, user_id]);

  useEffect(() => {
    if (userData) {
      actionGetActivities();
    }
  }, [userData, actionGetActivities]);

  return (
    <div>
      <SubNav
        title={userData ? userData.name_of_employee : "loading"}
        totalScore={userData && userData.current_score}
      />

      {allActivities.map((item) => {
        return (
          <div key={item.activity_id} className="bg-white rounded shadow  mb-4">
            <div className="p-4 font-bold flex gap-4">
              <span className="bg-theme-yellow text-white font-normal text-sm py-1 px-4 rounded-sm">
                {item.score}
              </span>
              {item.title}
            </div>
            {!item.details.length && <NoDataFound />}
            {item.activity_id === "activity_1" && item.details.length > 0 && (
              <>
                <Activity1 item={item} activity_id={item.activity_id} />
              </>
            )}
            {item.activity_id === "activity_2" && item.details.length > 0 && (
              <>
                <Activity2 item={item} activity_id={item.activity_id} />
              </>
            )}
            {item.activity_id === "activity_3" && item.details.length > 0 && (
              <>
                <Activity3 item={item} activity_id={item.activity_id} />
              </>
            )}
            {item.activity_id === "activity_4" && item.details.length > 0 && (
              <>
                <Activity4 item={item} activity_id={item.activity_id} />
              </>
            )}
            {item.activity_id === "activity_5" && item.details.length > 0 && (
              <>
                <Activity5 item={item} activity_id={item.activity_id} />
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Usermodules;
