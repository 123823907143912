import React, { useCallback, useRef, useState } from "react";
import SubNav from "../../../components/SubNav";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { useAdminContext } from "../../../context/AdminContext";
import { IoTrashBinOutline } from "react-icons/io5";

const AddReward = () => {
  const { addReward } = useAdminContext();
  const fileRef = useRef(null);
  const [imgs, setImgs] = useState("");
  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    role: Yup.string().required("Role is required"),
    photo: Yup.string().required(),
    selectedMonth: Yup.string().required("Month is required"),
  });
  const deleteFile = useCallback(
    (e, setFieldValue) => {
      setImgs("");
      setFieldValue("photo", "");
      if (!imgs.length) {
        fileRef.current.value = null;
        setImgs("");
        setFieldValue("photo", "");
      }
    },
    [imgs]
  );

  return (
    <>
      <SubNav title="Add Reward" />
      <div className="bg-white rounded shadow p-4 lg:p-8">
        <Formik
          validationSchema={validationSchema}
          initialValues={{
            title: "",
            role: "",
            selectedMonth: "",
            photo: "",
          }}
          onSubmit={(val, { resetForm }) => {
            addReward({
              ...val,
            });
            resetForm();
            setImgs("");
          }}
        >
          {({
            values,
            handleBlur,
            handleSubmit,
            errors,
            isValid,
            dirty,
            handleChange,
            setFieldValue,
          }) => (
            <Form className="space-y-3" onSubmit={handleSubmit}>
              <div className="relative group">
                <label htmlFor="title" className="form-label">
                  Title
                </label>
                <Field
                  id="title"
                  name="title"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="form-control"
                />
                <ErrorMessage
                  name="title"
                  component="div"
                  className="hasError"
                />
              </div>
              <div className="relative group">
                <label htmlFor="role" className="form-label">
                  Select Role
                </label>
                <Field
                  as="select"
                  className="form-control"
                  id="role"
                  name="role"
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option value="">Select role</option>
                  <option value="TM">TM</option>
                  <option value="AM">AM</option>
                  <option value="KAM_GAM">KAM - GAM</option>
                </Field>
                <ErrorMessage
                  name="role"
                  component="div"
                  className="hasError"
                />
              </div>
              <div className="relative group">
                <label htmlFor="photo" className="form-label">
                  Select Photo
                </label>
                <input
                  type="file"
                  ref={fileRef}
                  className="form-control"
                  name="photo"
                  onChange={(event) => {
                    if (event.target.files.length !== 0) {
                      setImgs(event.target.files[0]);
                      setFieldValue("photo", event.target.files[0]);
                    }
                    fileRef.current.value = null;
                  }}
                />
              </div>
              <div className="flex flex-wrap gap-2">
                {imgs && (
                  <div className="relative w-20">
                    <img
                      src={URL.createObjectURL(imgs)}
                      alt="elphoto"
                      width="w-full"
                    />
                    <div
                      className="bg-red-600 text-white p-2 w-full flex item-center justify-center cursor-pointer"
                      onClick={(e) => deleteFile(e, setFieldValue)}
                    >
                      <IoTrashBinOutline />
                    </div>
                  </div>
                )}
              </div>
              <div className="relative group">
                <label htmlFor="date" className="form-label">
                  Select Month
                </label>
                <Field
                  as="select"
                  className="form-control"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="selectedMonth"
                  name="selectedMonth"
                >
                  <option disabled value="">
                    Select
                  </option>
                  {new Date().getDate() < 11 && (
                    <option>
                      {moment(new Date())
                        .subtract(1, "months")
                        .endOf("month")
                        .format("MMMM YYYY")}
                    </option>
                  )}
                  <option>{moment(new Date()).format("MMMM YYYY")}</option>
                </Field>
                <ErrorMessage
                  name="selectedMonth"
                  component="div"
                  className="hasError"
                />
              </div>
              <button
                type="submit"
                className="btn w-full"
                disabled={!(isValid && dirty)}
              >
                Submit
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default AddReward;
