import React from "react";
import moment from "moment";
import { useAuthContext } from "../../../context/AuthContext";
const Activity5 = ({ item, activity_id, actionDeleteEntry }) => {
  const { authUser } = useAuthContext();
  return (
    <>
      <table>
        <thead>
          <tr>
            <th>Sr.</th>
            <th>Account Name</th>
            <th>UCM Id</th>
            <th>Image</th>
            <th>Activity Month</th>
            {authUser.role !== "admin" && <th>Action</th>}
          </tr>
        </thead>
        <tbody>
          {item.details.reverse().map((item, index) => {
            return (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{item.account_name}</td>
                <td>{item.ucm_id}</td>
                <td>
                    <div className="flex gap-2">
                      {item.image_urls.length > 0
                        ? item.image_urls.map((url, index) => {
                            return (
                              <a
                                data-fancybox={activity_id}
                                className="w-14"
                                key={index}
                                href={url}
                              >
                                <img
                                  src={url}
                                  alt="prvimg"
                                  className="w-full"
                                />
                              </a>
                            );
                          })
                        : "No image"}
                    </div>
                  </td>
                <td>
                  {moment(new Date().setMonth(item.month - 1)).format("MMMM")}
                  &nbsp;
                  {moment(new Date().setFullYear(item.year)).format("YYYY")}
                </td>
                {authUser.role !== "admin" && (
                  <td>
                    <button
                      className="btn !bg-red-500 !py-1 !text-xs !rounded-sm"
                      disabled={!item.can_delete}
                      onClick={(e) =>
                        actionDeleteEntry({
                          id: item.id,
                          activity_id: activity_id,
                        })
                      }
                    >
                      Delete
                    </button>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default Activity5;
