import { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import { AdminState } from "./context/AdminContext";
import { useAuthContext } from "./context/AuthContext";
import Dashboardlayout from "./layouts/Dashboardlayout";
import StaticLayout from "./layouts/StaticLayout";
import AddUser from "./pages/admin/add-user";
import Login from "./pages/auth/Login";
import Profile from "./pages/auth/Profile";
import Client from "./pages/client";
import Admin from "./pages/admin";
import Eligibility from "./pages/common/Eligibility";
import Leaderboard from "./pages/common/Leaderboard";
import Rewards from "./pages/admin/reward/Rewards";
import PageNotFound from "./pages/common/PageNotFound";
import Terms from "./pages/common/Terms";
import Unauthorised from "./pages/common/Unauthorised";
import CheckmateNewIbConversions from "./pages/modules/checkmate-new-ib-conversions";
import NoOfAccountsClosed from "./pages/modules/no-of-accounts-closed";
import RadaspireMrWebconnect from "./pages/modules/radaspire-mr-webconnect";
import RadaspireOrCardioaspireQuiz from "./pages/modules/radaspire-or-cardioaspire-quiz";
import VspRevenueAchievement from "./pages/modules/vsp-revenue-achievement";
import ProtectedRoute from "./ProtectedRoute";
import { ClientState } from "./context/ClientContext";
import Loader from "./components/Loader";
import AddReward from "./pages/admin/reward/AddReward";
import ShowReward from "./pages/client/ShowReward";
import ForgetPassword from "./pages/auth/ForgetPassword";
import UserProfile from "./pages/admin/add-user/UserProfile";
import Usermodules from "./pages/admin/usermodules";
import Report from "./pages/admin/Report";
import MonthlyPointReport from "./pages/admin/MonthlyPointReport";
import MonthlyActivityReport from "./pages/admin/MonthlyActivityReport";

const ScrollToTop = (props) => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return <>{props.children}</>;
};

function App() {
  const { authUser, isLoading } = useAuthContext();
  const [currentRole, setCurrentRole] = useState(null);

  useEffect(() => {
    if (authUser) {
      setCurrentRole(authUser.role);
    } else {
      setCurrentRole(null);
    }
  }, [authUser]);

  return (
    <ScrollToTop>
      {isLoading && <Loader />}

      <Routes>
        {authUser === null && (
          <>
            <Route path="/" element={<Login />} />
            <Route path="forgot-password" element={<ForgetPassword />} />
          </>
        )}
        {(currentRole === "TM" ||
          currentRole === "KAM_GAM" ||
          currentRole === "AM") && (
          <Route
            element={<ProtectedRoute allowedRole={["TM", "KAM_GAM", "AM"]} />}
          >
            <Route
              path="/"
              element={
                <ClientState>
                  <Dashboardlayout />
                </ClientState>
              }
            >
              <Route path="" element={<Client />} />
              <Route path="leaderboard" element={<Leaderboard />} />
              <Route path="show-rewards" element={<ShowReward />} />
              <Route path="eligibility" element={<Eligibility />} />
              <Route path="profile" element={<Profile />} />
              {/* modules */}
              <Route
                path="activity_1"
                element={<CheckmateNewIbConversions />}
              />
              <Route path="activity_2" element={<NoOfAccountsClosed />} />
              <Route
                path="activity_3"
                element={<RadaspireOrCardioaspireQuiz />}
              />
              <Route path="activity_4" element={<RadaspireMrWebconnect />} />
              {/* <Route path="activity_5" element={<VspRevenueAchievement />} /> */}
            </Route>
          </Route>
        )}
        {currentRole === "admin" && (
          <Route element={<ProtectedRoute allowedRole={["admin"]} />}>
            <Route
              path="/"
              element={
                <AdminState>
                  <Dashboardlayout />
                </AdminState>
              }
            >
              <Route path="" element={<Admin />} />
              <Route path="leaderboard" element={<Leaderboard />} />
              <Route path="rewards" element={<Rewards />} />
              <Route path="eligibility" element={<Eligibility />} />
              <Route path="profile" element={<Profile />} />
              <Route path="user/:user_id" element={<UserProfile />} />
              <Route path="add-user" element={<AddUser />} />
              <Route path="add-reward" element={<AddReward />} />
              <Route path="usermodules/:user_id" element={<Usermodules />} />
              <Route path="report" element={<Report />} />
              <Route
                path="monthlyPointReport"
                element={<MonthlyPointReport />}
              />
              <Route
                path="monthlyActivityReport"
                element={<MonthlyActivityReport />}
              />
            </Route>
          </Route>
        )}
        <Route element={<StaticLayout />}>
          <Route path="terms" element={<Terms />} />
          <Route path="unauthorized" element={<Unauthorised />} />
          <Route path="*" element={<PageNotFound />} />
        </Route>
      </Routes>
    </ScrollToTop>
  );
}

export default App;
