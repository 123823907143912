import React from "react";
import { Link } from "react-router-dom";
import SubNav from "../../components/SubNav";
import { useAdminContext } from "../../context/AdminContext";

const Admin = () => {
  const { allUsers } = useAdminContext();
  return (
    <div>
      <SubNav title="All Active users" />
      <div className="bg-white rounded shadow p-4 overflow-auto w-full">
        {allUsers && allUsers.length > 0 ? (
          <>
            <table className="text-sm">
              <thead>
                <tr>
                  <th>Sr.</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Role</th>
                  <th>Region</th>
                  <th>Points</th>
                  <th>action</th>
                </tr>
              </thead>
              <tbody>
                {allUsers.map((user, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{user.name_of_employee}</td>
                      <td>{user.user_name}</td>
                      <td>{user.designation}</td>
                      <td>{user.region}</td>
                      <td>{user.current_score}</td>
                      <td>
                        <div className="flex gap-2">
                          <Link
                            to={`user/${user.user_id}`}
                            className="btn !py-1 !text-xs !bg-green-600 !rounded-sm"
                          >
                            Profile
                          </Link>
                          <Link
                            to={`usermodules/${user.user_id}`}
                            className="btn !py-1 !text-xs !bg-blue-600 !rounded-sm"
                          >
                            Details
                          </Link>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </>
        ) : (
          "Loading"
        )}
      </div>
    </div>
  );
};

export default Admin;
