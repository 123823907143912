import React from "react";
import moment from "moment";
import { useAuthContext } from "../../../context/AuthContext";
const Activity1 = ({ item, activity_id, actionDeleteEntry }) => {
  const { authUser } = useAuthContext();
  return (
    <>
      <table>
        <thead>
          <tr>
            <th>Sr.</th>
            <th>Account Name</th>
            <th>UCM ID</th>
            <th>Clariscan Qty.</th>
            <th>Omniscan Qty.</th>
            <th>Activity Month</th>
            {authUser.role !== "admin" && <th>Action</th>}
          </tr>
        </thead>
        <tbody>
          {item.details.reverse().map((item, index) => {
            return (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{item.account_name}</td>
                <td>{item.ucm_id}</td>
                <td>{item.clariscan}</td>
                <td>{item.omniscan}</td>
                <td>
                  {moment(new Date().setMonth(item.month - 1)).format("MMMM")}
                  &nbsp;
                  {moment(new Date().setFullYear(item.year)).format("YYYY")}
                </td>
                {authUser.role !== "admin" && (
                  <td>
                    <button
                      className="btn !bg-red-500 !py-1 !text-xs !rounded-sm"
                      disabled={!item.can_delete}
                      onClick={(e) =>
                        actionDeleteEntry({
                          id: item.id,
                          activity_id: activity_id,
                        })
                      }
                    >
                      Delete
                    </button>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default Activity1;
