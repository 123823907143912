import { apiService } from "../services/apiService";
import {
  createContext,
  useCallback,
  useContext,
  useState,
  useEffect,
} from "react";
import { toast } from "react-toastify";
import { useAuthContext } from "./AuthContext";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const AdminContext = createContext();

export const AdminState = (props) => {
  const { authUser, setIsLoading } = useAuthContext();
  const [allUsers, setAllUsers] = useState([]);
  const [rewards, setRewards] = useState([]);
  let navigate = useNavigate();

  const actionGetRewards = useCallback(async () => {
    setIsLoading(true);
    try {
      const resp = await apiService.post(
        "",
        {
          operation: "get_all_rewards",
          user_name: authUser.user.user_name,
          request_date: moment(new Date()).format("YYYY-MM-DD"),
          token: authUser.token,
        },
        { headers: { Authorization: "Bearer " + authUser.token } }
      );
      if (resp.data.status === 200) {
        setRewards(resp.data.rewards);
      }

      setIsLoading(false);
    } catch (error) {
      toast.error(error.message);
      console.log(error);
      setIsLoading(false);
    }
  }, [authUser.token, setIsLoading, authUser.user.user_name]);

  const actionGetUsers = useCallback(async () => {
    setIsLoading(true);
    try {
      const resp = await apiService.post("", {
        operation: "admin_all_users",
        user_name: authUser.user.user_name,
        request_date: moment(new Date()).format("YYYY-MM-DD"),
        token: authUser.token,
      });
      if (resp.data.status === 200) {
        setAllUsers(resp.data.all_users);
      }

      setIsLoading(false);
    } catch (error) {
      toast.error(error.message);
      console.log(error);
      setIsLoading(false);
    }
  }, [authUser.token, setIsLoading, authUser.user.user_name]);

  const addReward = async (val) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("upload_file", val.photo);
    try {
      const resp = await apiService.post(`/file_upload.php`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      let bannerPath = resp.data.filename;
      if (bannerPath) {
        setIsLoading(false);
        uploadReward(bannerPath, val);
      } else {
        toast.error("something went wrong, please try again.");
        setIsLoading(false);
      }
      navigate("/rewards");
    } catch (error) {
      toast.error(error.message);
      console.log(error);
      setIsLoading(false);
    }
  };

  const uploadReward = async (bannerPath, val) => {
    if (bannerPath) {
      setIsLoading(true);
      try {
        const resp = await apiService.post("", {
          operation: "create_reward",
          user_name: authUser.user.user_name,
          request_date: moment(new Date()).format("YYYY-MM-DD"),
          token: authUser.token,
          title: val.title,
          image_url: bannerPath,
          role: val.role,
          month: moment(val.selectedMonth, "MMMM YYYY").format("M"),
          year: moment(val.selectedMonth, "MMMM YYYY").format("Y"),
        });
        if (resp.data.status === 200) {
          actionGetRewards();
          toast.success("Activity added successfully!");
        }
        if (resp.data.status === 400) {
          actionGetRewards();
          toast.warn("Reward already exists for this date");
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        toast.error(error.message);
        console.log(error);
      }
    }
  };

  const actionRemoveReward = async (item) => {
    console.log(item);
    setIsLoading(true);
    try {
      const resp = await apiService.post("", {
        operation: "delete_reward",
        user_name: authUser.user.user_name,
        request_date: moment(new Date()).format("YYYY-MM-DD"),
        token: authUser.token,
        role: item.role,
        month: item.month,
        year: item.year,
      });
      if (resp.data.status === 200) {
        actionGetRewards();
        toast.success("Reward deleted successfully!");
      }
    } catch (error) {
      setIsLoading(false);
      toast.error(error.message);
      console.log(error);
    }
  };

  useEffect(() => {
    actionGetRewards();
    actionGetUsers();
  }, [actionGetRewards, actionGetUsers]);

  const addUser = async (
    name_of_employee,
    user_name,
    password,
    mobile_no,
    designation,
    region
  ) => {
    setIsLoading(true);
    try {
      const resp = await apiService.post("", {
        users: [
          {
            region: region,
            name_of_employee: name_of_employee,
            user_name: user_name,
            password: password,
            mobile_no: mobile_no,
            designation: designation,
            role: "TM",
          },
        ],
        operation: "onboard_users",
        user_name: authUser.user.user_name,
        request_date: moment(new Date()).format("YYYY-MM-DD"),
        token: authUser.token,
      });
      if (resp.data.status === 200) {
        toast.success("New user added successfully!");
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error(error.message);
      console.log(error);
    }
  };

  return (
    <AdminContext.Provider
      value={{
        addUser,
        addReward,
        rewards,
        actionRemoveReward,
        allUsers,
        setAllUsers,
      }}
    >
      {props.children}
    </AdminContext.Provider>
  );
};

export const useAdminContext = () => {
  return useContext(AdminContext);
};
