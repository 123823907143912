import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useClientContext } from "../../context/ClientContext";
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import BarChartPanel from "../../components/BarChartPanel";

import BadgeGold from "./../../assets/badge-gold.svg";
import BadgeSilver from "./../../assets/badge-silver.svg";
import BadgeBronze from "./../../assets/badge-bronze.svg";

// const percentage = 66;
const ListCard = ({ item }) => {
  return (
    <Link
      to={item.activity_id}
      className="pl-2 pt-2 relative w-full overflow-hidden"
    >
      <div className="flex shadow rounded  bg-[#E0B0FF] p-4 gap-4  hover:shadow-md group transition-all ease-in-out duration-300 relative">
        <div className="absolute -top-2 -left-2 w-14 h-14 self-start bg font-bold bg-[#642D91] group-hover:bg-slate-800 text-white group-hover:shadow-md transition-all ease-in-out duration-300 rounded shadow">
          <div className="w-14 h-14 flex flex-col items-center justify-center  ">
            <div className="text-sm leading-4">{item.score}</div>
            <div className="font-light text-xs">Points</div>
          </div>
        </div>
        <div className="pl-12 overflow-hidden">
          <h4 className="font-bold text-slate-800 group-hover:text-theme-yellow transition-all ease-in-out duration-300 leading-5">
            {item.title}
          </h4>
          <p className="text-sm text-slate-600">{item.subtitle}</p>
          <p className="text-sm text-slate-800 truncate">{item.description}</p>
        </div>
      </div>
    </Link>
  );
};
const Client = () => {
  const { allActivities, allScore, monthlyScore, percentage } =
    useClientContext();

  const [selectMonth, setSelectMonth] = useState("");
  useEffect(() => {
    setSelectMonth(
      Object.keys(monthlyScore)[Object.keys(monthlyScore).length - 1]
    );
  }, [allScore.current_score, allScore.target_score, monthlyScore]);

  return (
    <>
      <div className="grid  lg:grid-cols-3 gap-4 lg:gap-8 mb-8">
        <div className=" lg:col-span-1 bg-white p-4 rounded shadow text-center">
          <div className="mb-4">
            <div className=" flex items-center justify-center">
              <div className="text-center">
                <h4 className="font-bold text-lg">
                  Overall points: {allScore.current_score}
                </h4>
                <h4>Target points: {allScore.target_score}</h4>
              </div>
            </div>
          </div>

          <CircularProgressbarWithChildren
            circleRatio={0.75}
            value={percentage}
            text={`${percentage}%`}
            styles={buildStyles({
              rotation: 1 / 2 + 1 / 8,
              textColor: "#232323",
              pathColor: "#642D91",
              strokeLinecap: "butt",
              trailColor: "#eee",
            })}
          >
            {percentage >= 25 && percentage < 50 ? (
              <div className="absolute bottom-0 flex items-center flex-col justify-center text-center">
                <div className="text-white font-light text-sm mb-2 bg-theme-yellow py-1 px-4 rounded">
                  Bronze Badge
                </div>
                <img src={BadgeBronze} alt="badge" className={`w-12`} />
              </div>
            ) : percentage >= 50 && percentage < 75 ? (
              <div className="absolute bottom-0 flex items-center flex-col justify-center text-center">
                <div className="text-white font-light text-sm mb-2 bg-theme-yellow py-1 px-4 rounded">
                  Silver Badge
                </div>
                <img src={BadgeSilver} alt="badge" className={`w-12`} />
              </div>
            ) : percentage >= 75 && percentage <= 100 ? (
              <div className="absolute bottom-0 flex items-center flex-col justify-center text-center">
                <div className="text-white font-light text-sm mb-2 bg-theme-yellow py-1 px-4 rounded">
                  Gold Badge
                </div>
                <img src={BadgeGold} alt="badge" className={`w-12 mr-auto`} />
              </div>
            ) : null}
          </CircularProgressbarWithChildren>
        </div>

        <div className="lg:col-span-2 bg-white p-4 rounded shadow">
          <BarChartPanel
            monthlyScore={monthlyScore}
            selectMonth={selectMonth}
            setSelectMonth={setSelectMonth}
          />
        </div>
      </div>
      <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-8">
        {!allActivities.length
          ? "loading ..."
          : allActivities.map((item, index) => {
              return <ListCard key={index} item={item} />;
            })}
      </div>
    </>
  );
};

export default Client;
