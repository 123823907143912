import React from "react";
import { useClientContext } from "../context/ClientContext";
import BadgeGold from "./../assets/badge-gold.svg";
import BadgeSilver from "./../assets/badge-silver.svg";
import BadgeBronze from "./../assets/badge-bronze.svg";

const BadgeArchivement = () => {
  const { showBadge, setShowBadge } = useClientContext();

  return (
    <>
      {showBadge.show ? (
        <div className="bg-gray-900/80 fixed top-0 left-0 w-full h-full z-[12] flex items-start justify-center pt-32">
          <div className="bg-white rounded p-4 flex gap-4 shadow-2xl items-center justify-center">
            <div className="space-y-1">
              <h4 className="text-xl font-bold leading-4">Congratulations!</h4>
              <p>
                You've earn a{" "}
                <span className="text-theme-yellow">
                  {showBadge.badge === "Bronze"
                    ? "Bronze"
                    : showBadge.badge === "Silver"
                    ? "Silver"
                    : showBadge.badge === "Gold"
                    ? "Gold"
                    : null}
                </span>{" "}
                badge
              </p>
              <button
                className="btn"
                onClick={() => setShowBadge({ show: false, badge: null })}
              >
                Collect
              </button>
            </div>
            <div className="bg-slate-200 w-16 flex items-center justify-center rounded pb-2">
              <img
                src={
                  showBadge.badge === "Bronze"
                    ? BadgeBronze
                    : showBadge.badge === "Silver"
                    ? BadgeSilver
                    : showBadge.badge === "Gold"
                    ? BadgeGold
                    : null
                }
                alt="badge"
                className="h-16"
              />
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default BadgeArchivement;
