import React, { useEffect, useState, useRef, useCallback } from "react";
import { useLocation } from "react-router-dom";
import SubNav from "../../../components/SubNav";
import { useClientContext } from "../../../context/ClientContext";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { IoTrashBinOutline } from "react-icons/io5";
import NoDataFound from "../../../components/NoDataFound";
import Activity4 from "./Activity4";

const RadaspireMrWebconnect = () => {
  const { allActivities, submitActivity, actionDeleteEntry } = useClientContext();
  const { pathname } = useLocation();
  const [currentActivity, setCurrentActivity] = useState(null);
  const [imgs, setImgs] = useState([]);
  const fileRef = useRef(null);

  useEffect(() => {
    if (allActivities.length) {
      let myActivity = allActivities.find((item) => {
        return item.activity_id === pathname.substring(1);
      });
      setCurrentActivity(myActivity);
    }
  }, [allActivities, pathname]);

  const validationSchema = Yup.object().shape({
    account_name: Yup.string().required("Account name is required"),
    ucm_id: Yup.string().required("UCM Id is required"),
    photo: Yup.array(),
    selectedMonth: Yup.string().required("Month is required"),
  });

  const deleteFile = useCallback(
    (e, setFieldValue) => {
      const s = imgs.filter((item, index) => index !== e);
      setImgs(s);
      setFieldValue("photo", s);
      if (!imgs.length) {
        fileRef.current.value = null;
        setImgs([]);
        setFieldValue("photo", []);
      }
    },
    [imgs]
  );

  if (currentActivity === null) {
    return "loading";
  }

  return (
    <div>
      <SubNav
        title={currentActivity.title}
        subtitle={currentActivity.subtitle}
        desc={currentActivity.description}
        activity_score={currentActivity.score}
        target={
          currentActivity.activity_limit * currentActivity.score_per_activity
        }
      />
      <div className="grid gap-4 lg:grid-cols-3 ">
        <div className="bg-white p-4 lg:p-8 w-full shadow rounded">
          <Formik
            validationSchema={validationSchema}
            initialValues={{
              account_name: "",
              ucm_id:"",
              photo: [],
              selectedMonth: "",
            }}
            onSubmit={(val, { resetForm }) => {
              submitActivity({
                ...val,
                activity_id: currentActivity.activity_id,
              });
              resetForm();
              setImgs([]);
            }}
          >
            {({
              values,
              handleBlur,
              handleSubmit,
              errors,
              isValid,
              dirty,
              handleChange,
              setFieldValue,
            }) => (
              <Form className="space-y-3" onSubmit={handleSubmit}>
                <div className="relative group">
                  <label htmlFor="participant" className="form-label">
                    Account Name
                  </label>
                  <Field
                    id="account_name"
                    name="account_name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="form-control"
                  />
                  <ErrorMessage
                    name="account_name"
                    component="div"
                    className="hasError"
                  />
                </div>

                <div className="relative group">
                  <label htmlFor="participant" className="form-label">
                     UCM Id
                  </label>
                  <Field
                    id="ucm_id"
                    name="ucm_id"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="form-control"
                  />
                  <ErrorMessage
                    name="ucm_id"
                    component="div"
                    className="hasError"
                  />
                </div>

                <div className="relative group">
                  <label htmlFor="photo" className="form-label">
                    Attach Photo
                  </label>
                  <input
                    type="file"
                    ref={fileRef}
                    className="form-control"
                    name="photo"
                    onChange={(event) => {
                      if (event.target.files.length !== 0) {
                        setImgs([...imgs, event.target.files[0]]);
                        setFieldValue("photo", [
                          ...imgs,
                          event.target.files[0],
                        ]);
                      }
                      fileRef.current.value = null;
                    }}
                  />
                </div>

                <div className="flex flex-wrap gap-2">
                  {imgs.length > 0 &&
                    imgs.map((item, index) => {
                      return (
                        <div className="relative w-20" key={index}>
                          <img
                            src={URL.createObjectURL(item)}
                            alt="elphoto"
                            width="w-full"
                          />
                          <div
                            className="bg-red-600 text-white p-2 w-full flex item-center justify-center cursor-pointer"
                            onClick={(e) => deleteFile(index, setFieldValue)}
                          >
                            <IoTrashBinOutline />
                          </div>
                        </div>
                      );
                    })}
                </div>

                <div className="relative group">
                  <label htmlFor="date" className="form-label">
                    Select Month
                  </label>
                  <Field
                    as="select"
                    className="form-control"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="selectedMonth"
                    name="selectedMonth"
                  >
                    <option disabled value="">
                      Select
                    </option>
                    {moment("2024/09/24", "YYYY/MM/DD").isAfter(moment()) ? (
                      <>
                        <option>
                          {moment(new Date())
                            .subtract(1, "months")
                            .endOf("month")
                            .format("MMMM YYYY")}
                        </option>
                        <option>
                          {moment(new Date()).format("MMMM YYYY")}
                        </option>
                      </>
                    ) : (
                      <>
                        {new Date().getDate() < 11 && (
                          <option>
                            {moment(new Date())
                              .subtract(1, "months")
                              .endOf("month")
                              .format("MMMM YYYY")}
                          </option>
                        )}
                        <option>
                          {moment(new Date()).format("MMMM YYYY")}
                        </option>
                      </>
                    )}
                  </Field>
                  <ErrorMessage
                    name="selectedMonth"
                    component="div"
                    className="hasError"
                  />
                </div>
                <button
                  type="submit"
                  className="btn w-full"
                  disabled={!(isValid && dirty)}
                >
                  Submit
                </button>
              </Form>
            )}
          </Formik>
        </div>
        <div className="bg-white p-4 lg:p-8 w-full shadow rounded overflow-auto lg:col-span-2">
          {currentActivity.details.length > 0 ? (
            <>
              <Activity4
                item={currentActivity}
                activity_id={currentActivity.activity_id}
                actionDeleteEntry={actionDeleteEntry}
              />
            </>
          ) : (
            <NoDataFound />
          )}
        </div>
      </div>
    </div>
  );
};

export default RadaspireMrWebconnect;
