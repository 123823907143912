import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { AuthState } from "./context/AuthContext";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";

ReactDOM.render(
  <BrowserRouter>
    <AuthState>
      <App />
      <ToastContainer theme="colored" autoClose={1000} position="top-center" />
    </AuthState>
  </BrowserRouter>,
  document.getElementById("root")
);
