import { useNavigate } from "react-router-dom";

const Terms = () => {
  let navigate = useNavigate();
  return (
    <section className="py-8">
      <div className="container mx-auto">
        <h4 className="text-lg font-bold mb-4">Terms & conditions</h4>
        <div className="space-y-4 ">
          <p>
            This initiative titled Triwizard campaign is an initiative by GE for
            monitoring and recognizing their internal customers (employees) on
            the basis of their sales performance. By using the link sent by us
            to you which allows you to enter and monitor performance details on
            the “Triwizard” (Site) using the link for Triwizard campaign, you
            hereby understand, confirm, consent and acknowledge to the
            following:
          </p>
          <ol className="list-decimal pl-4 space-y-2">
            <li>
              The Site allows you to enter details to various parameters of
              marketing and sales campaign using the link.
            </li>
            <li>
              You confirm that you have full right to use the data entered by
              you. You are responsible for the data appropriateness thereof. The
              link and any data point entered by you using the link is not
              publicly available unless you transmit the same.
            </li>
            <li>
              You understand and agree that the data you have entered as a part
              of Triwizard campaign is voluntary and any transmission thereof is
              your responsibility.
            </li>
            <li>
              You are not permitted to transmit or share the link with any other
              individual and only have the right to enter your own data.
            </li>
            <li>
              GE shall not be liable for improper use of the postcard and/or any
              usage or transmission which is not in keeping with the above
              terms.
            </li>
            <li>
              This web application and/or initiative are available for usage
              only by GE internal sales staff/employees who have received the
              link directly from GE.
            </li>
          </ol>
          <button className="btn" onClick={() => navigate(-1)}>
            Go back
          </button>
        </div>
      </div>
    </section>
  );
};

export default Terms;
