import { useEffect, useState } from "react";
import { useAuthContext } from "../../context/AuthContext";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Logo from "./../../assets/new_logo.png";
import { useLocation, useNavigate, Link } from "react-router-dom";
import LoginBg from "./../../assets/loginBg.jpg";
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";
const Login = () => {
  const { authUser, actionLogin } = useAuthContext();
  const [showPass, setShowPass] = useState(false);
  let navigate = useNavigate();
  let location = useLocation();
  let { from } = location.state || { from: { pathname: "/" } };
  const validationSchema = Yup.object().shape({
    loginId: Yup.string().required("Login Id is required"),
    password: Yup.string().required("Password Id is required"),
    terms: Yup.bool().oneOf(
      [true],
      "You must accept the terms & condition before sign in!"
    ),
  });

  useEffect(() => {
    if (authUser) {
      navigate(from);
    }
  }, [authUser, from, navigate]);
  return (
    <div
      className="min-h-screen flex items-center justify-center w-full flex-col px-6 bg-center bg-cover"
      style={{ backgroundImage: `url(${LoginBg})` }}
    >
      <div className="max-w-[420px] mx-auto p-6 flex flex-col justify-center gap-8 bg-white rounded-md shadow-lg">
        <img src={Logo} alt="logo" width={"456px"} className="mx-auto" />
        {/* <div className="text-slate-800 leading-5 text-center font-bold text-lg">
          Log in your account & manage your task
        </div> */}

        <Formik
          initialValues={{
            loginId: "",
            password: "",
            terms: false,
          }}
          validationSchema={validationSchema}
          onSubmit={(val) => {
            actionLogin(val.loginId, val.password);
            // actionLogin();
          }}
        >
          {({
            handleBlur,
            handleSubmit,
            errors,
            isValid,
            dirty,
            handleChange,
          }) => (
            <>
              <Form className="space-y-3" onSubmit={handleSubmit}>
                <div className="relative group">
                  <label htmlFor="loginId" className="form-label">
                    Login ID
                  </label>
                  <Field
                    id="loginId"
                    name="loginId"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="form-control"
                  />
                  <ErrorMessage
                    name="loginId"
                    component="div"
                    className="hasError"
                  />
                </div>
                <div className="relative group">
                  <label htmlFor="password" className="form-label">
                    Password
                  </label>
                  <div className="relative w-full">
                    <button
                      type="button"
                      onClick={(e) => setShowPass(!showPass)}
                      className="absolute right-0 top-0 bottom-0 px-2"
                    >
                      {showPass ? <IoEyeOutline /> : <IoEyeOffOutline />}
                    </button>
                    <Field
                      type={showPass ? "text" : "password"}
                      id="password"
                      name="password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="form-control"
                    />
                  </div>
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="hasError"
                  />
                </div>
                <div className="relative group">
                  <label className="inline-flex">
                    <Field
                      type="checkbox"
                      id="terms"
                      name="terms"
                      className="rounded border-gray-300 text-theme-blue-600 shadow-sm focus:border-blue-300 focus:ring focus:ring-offset-0 focus:ring-theme-blue-200 focus:ring-opacity-50 mt-1"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <span className="ml-2 text-sm text-slate-700 font-light">
                      I have read and agree{" "}
                      <Link
                        to="/terms"
                        className="text-theme-blue-500 underline"
                      >
                        to the terms and conditions
                      </Link>
                      .
                    </span>
                  </label>
                  <ErrorMessage
                    name="terms"
                    component="div"
                    className="hasError"
                  />
                </div>

                <button
                  type="submit"
                  className="btn w-full"
                  disabled={!(isValid && dirty)}
                >
                  Sign in
                </button>
                {/* <div className="text-right">
                  <a
                    href="mailto:Sanil.Chawla@ge.com, sachin.desai@solmc.in
                    ?subject=Please reset my password&body=Hello Admin, I have forgot my password for TRIWIZARD App, Please reset my password"
                    className="text-sm"
                  >
                    Forgot password
                  </a>
                </div> */}
                <div className="text-right">
                  <Link to="forgot-password" className="text-sm">
                    Forgot password
                  </Link>
                </div>
              </Form>
            </>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Login;
